import { ActionTypes } from "../constants/ActionTypes";

const initialState ={
    sidebar_status:"open"
}

export const sidebarStatusReducer = (state = initialState.sidebar_status,{type,payload})=>{
    switch(type){
        case ActionTypes.SET_SIDEBAR_STATUS:
            return {...state,sidebar_status:payload};
        default :
            return state;
    }
}