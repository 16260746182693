import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../redux/constants/ActionTypes";

function Login() {
  // const onFinish = (values) => {
  //   console.log("Success:", values);
  // };

  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const dispatch = useDispatch()

  function handleClick() {
    navigate('/admin');
    // <Navigate to ="/"/>
  };

  const onFinish = (values) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/adminapi/login`, {
      email: values?.email,
      password: values?.password
    }).then((response) => {
      if ( response.status >= 200 && response.status < 300) {
        if (response.data.status === true) {
          localStorage.setItem("adminInfo", JSON.stringify(response.data.data));
          localStorage.setItem("authToken", response.data.authtoken)
          localStorage.setItem("loggedIn", true);
          dispatch({
            type: ActionTypes.ADMIN_LOGIN_SUCCESS,
            payload: { admin: response.data.data }
          });
          setLoading(false)
          handleClick()
        } else {
          message.error(response.data.message)
          setLoading(false)
        }
      } else {
        message.error(response.message)
        setLoading(false)
      }
    }).catch((error) => {
      // Server can't be reached!
     
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error(error.message)
      }
    });

  };

  return (
    <div className="container-fluid ">
      <div className="row ">
        <div className="col-md-12 px-0">
          <div className="form-bg ">
            <div className="form-card col-md-4  bg-white">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <img src="images/logo.png" alt="Logo" />
                </div>
                {/* <div className="col-md-12 mt-3">
                  <p className="login-form-title">
                   Oliver Rubber
                  </p>
                </div> */}
                <div className="col-md-12 log-in-title">
                  Log In to continue...
                </div>
                <div className="col-md-12 mt-5">
                  <Form
                    name="login"
                    initialValues={{
                      remember: true
                    }}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    autoComplete="off"
                  >
                    <div className="col-md-12 ">
                      <Form.Item
                        label="email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Please input your email!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>

                    <div className="col-md-12">
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!"
                          }
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Form.Item>
                          <Button htmlType="submit" className="form-btn border-0">
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <p className="text-end fs-6"><Link to="/forgot-password" className="font-black fw-500">Forgot Password ?</Link></p>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
