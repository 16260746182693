import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Upload, message, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import instance from "../../../api/api_instance";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactQuill from "react-quill";
import ButtonLoader from "../../ButtonLoader";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ImageSeoModal from "../../ImageSeoModal";

function AboutHistory() {
  const [form] = Form.useForm();
  const [prevHistoryImg, setPrevHistoryImg] = useState(null);
  const [value, setValue] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const onFinish = (values) => {
    setBtnLoading(true);
    let formData = new FormData();
    formData.append("heading", values?.heading);
    formData.append("description", values?.description ?? "");
    if (values?.history_image) {
      formData.append(
        "history_image",
        values?.history_image?.fileList[0]?.originFileObj
      );
    }

    var config = {
      url: `/about/history/insert`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multiplart/form-data",
      },
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            get_data();
            setBtnLoading(false);
            form.resetFields(["history_image"]);
          } else {
            setBtnLoading(false);
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          setBtnLoading(false);
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          setBtnLoading(false);
          message.error(error?.response?.data?.message);
        } else {
          setBtnLoading(false);
          message.error(error?.message);
        }
      });
  };

  const get_data = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/about/history`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            form.setFieldsValue({
              heading: response?.data?.data?.heading,
              description: response?.data?.data?.description,
            });
            setValue(response?.data?.data?.description);
            setPrevHistoryImg(response?.data?.data?.image);
            form.resetFields(["history_image"]);
            message.success({ key: "loading", content: "Data Loaded." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };
 
  useEffect(() => {
    get_data();
  }, []);

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">About History</p>
      </div>
      <div className="card-body">
        <Form
          name="product_form"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <Form.Item
                    name="heading"
                    label="Heading"
                    rules={[
                      {
                        required: true,
                        message: "Please input heading!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Heading" />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input description!",
                      },
                    ]}
                  >
                  
                    {/* <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={value}
                      // onChange={setValue}
                    /> */}
                    <SunEditor
                      setContents={value} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"500px"
                      }}
                    />
                    {/* <TextArea rows="4" placeholder="Enter Description" /> */}
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <Form.Item
                name="history_image"
                label="Image"
                rules={[
                  {
                    required: prevHistoryImg ? false : true,
                    message: "Please upload image!",
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  accept="image/*"
                  multiple={false}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}></Button>
                </Upload>
              </Form.Item>
            </div>
            <div className="col-md-3">
              {prevHistoryImg ? (
                <div className="border p-2">
                  <Image
                    src={`${
                      process.env.REACT_APP_API_URL + "/" + prevHistoryImg?.path
                    }`}
                    alt=""
                    className="img-fluid form-banner-img"
                  />
                  <ImageSeoModal imageId={prevHistoryImg?._id} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-2">
              <Form.Item className="text-end">
                <Button
                  htmlType="submit"
                  className="form-btn-style"
                  disabled={btnLoading ? true : false}
                >
                  {btnLoading ? (
                    <span className="me-2">
                      <ButtonLoader />
                    </span>
                  ) : (
                    ""
                  )}
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AboutHistory;
