import { message } from "antd";
import React, { useEffect, useState } from "react";
import instance from "../api/web_instance";
import Cookies from "js-cookie";

export default function TranslateComponent() {
  const [languages, setLanguages] = useState([]);

  // var languageCookie = Cookies.get("googtrans")?.split("/");

  // languageCookie && languageCookie?.length > 2
  // ? languageCookie[2]
  // : navigator.language
  // const [defaultLanguage, setDefaultLanguage] = useState(navigator.language);
 
  // '/${window.navigator.language}'/'${defaultLanguage}'

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.defer = true;
  //   window.onload = function(){
  //     script.innerHTML = `function googleTranslateElementInit() {
  //       setCookie('googtrans', '/auto/${navigator.language}', 1);
  //       new google.translate.TranslateElement(
  //         {
  //           pageLanguage: "en",
  //           includedLanguages: ${
  //             languages?.length > 0
  //               ? "'" + languages?.toString() + "'"
  //               : "'" + "en,hi" + "'"
  //           },
  //           layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
  //           autoDisplay: false,
  //         },
  //         "google_translate_element"
  //       );
  //     }
  
  //     googleTranslateElementInit()
     
  //     `;
  //   }
  //   // document.body.appendChild(script);
  //   document.body.insertBefore(script,document.getElementById('goog-gt-'));
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [languages]);

  // GET NAVIGATIN DATA

  useEffect(() => {

    Cookies.remove('googtrans')
    // const script = document.createElement("script");
    // script.defer = true;
    const get_data = () => {
      const script = document.createElement("script");
      script.defer = true;
  
      var config = {
        url: `/home/languages`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            if (response.data.status === true) {
              setLanguages(response.data.data.languages);
              script.innerHTML = `function googleTranslateElementInit() {
                setCookie('googtrans', '/auto/${navigator.language}', 1);
                new google.translate.TranslateElement(
                  {
                    pageLanguage: "en",
                    includedLanguages: ${
                      response.data.data.languages?.length > 0
                        ? "'" + response.data.data.languages?.toString() + "'"
                        : "'" + "en,hi" + "'"
                    },
                    layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                    autoDisplay: false,
                  },
                  "google_translate_element"
                );
              }
          
              googleTranslateElementInit()
             
              `;
             
              document.body.insertBefore(script,document.getElementById('goog-gt-'));
              // if (
              //   response?.data?.data?.domains &&
              //   response?.data?.data?.domains?.length > 0
              // ) {
              //   const domains = response?.data?.data?.domains;
              //   var currentDomain = domains.find(
              //     (element) => element?.url == window.location.hostname
              //   );
              //   setDefaultLanguage(currentDomain?.default_language);
  
              //   // Cookies.remove('googtrans')
              //   // console.log("default language",currentDomain?.default_language)
              //   // console.log("andr",defaultLanguage);
              //   // Cookies.set('googtrans', `/auto/${defaultLanguage}`)
              // }
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    };
    get_data();
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return (
    <div
      id="google_translate_element"
      className="translate-language-selector"
    ></div>
  );
}
// function googleTranslateElementInit() {
//   new google.translate.TranslateElement({
//     pageLanguage: 'hi',
//     includedLanguages: 'en,es,fr,de,hi',
//     layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
//   }, 'google_translate_element');
// }
