import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import instance from "../../api/web_instance";
import { useEffect } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import ShimmerEffect from "../../component/ShimmerEffect";
const { TextArea } = Input;

export default function ProductDetailsPage() {
  const [form] = Form.useForm();
  const { slug } = useParams();

  const { state } = useLocation();
  const [productDetails, setProductDetails] = useState({});
  const [visibleImage, setVisibleImage] = useState(0);
  const [enquiryHeadings, setEnquiryHeadings] = useState({});
  const [categorySlug, setCategorySlug] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      productDetails?.images?.length >= 3 ? 3 : productDetails?.images?.length,
    slidesToScroll:
      productDetails?.images?.length >= 2 ? 2 : productDetails?.images?.length,
    // autoplay: true,
    // speed: 1000,
    // autoplaySpeed: 1000,
  };

  const onFinish = (values) => {
    const enquiry_data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      comment: values.comment,
      enquiry_for: productDetails?.name,
    };
    var config = {
      url: `/enquiry/create`,
      method: "POST",
      data: enquiry_data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({
              content: response.data.message,
              key: "loading",
            });
            form.resetFields();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const get_product_data = () => {
    setIsLoading(true);
    var config = {
      url: `/product-details/${slug}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setProductDetails(response?.data?.data);
            setEnquiryHeadings(response?.data?.enquiry_headings);
            setIsLoading(false);
          } else {
            message.error(response.data.message);
            setIsLoading(false);
          }
        } else {
          message.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_product_data();
  }, [state]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    var name = productDetails?.category?.name ?? "";

    var convertToLower = name ? name?.toLowerCase() : "";

    if (name) {
      // var slug = convertToLower.split(/[' '/.()]+/).join("-");

      // var some = encodeURI(slug);
      setCategorySlug(productDetails?.category?.category_slug);
    } else {
      setCategorySlug("all");
    }
  }, [productDetails]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {productDetails?.head_title
            ? productDetails.head_title
            : "Oliver Rubber"}
        </title>
        <meta
          name="title"
          content={
            productDetails?.meta_title
              ? productDetails.meta_title
              : "Oliver Rubber"
          }
        ></meta>
        <meta
          name="description"
          content={
            productDetails?.meta_description
              ? productDetails.meta_description
              : "Oliver Rubber"
          }
        ></meta>
        <meta
          name="keyword"
          content={
            productDetails?.meta_keyword ? productDetails.meta_keyword : ""
          }
        ></meta>
        <link
          rel="canonical"
          href={`https://oliverllp.com/product-details/${slug}`}
        />
      </Helmet>
      {/* <Navbarweb /> */}
      {/* <header className="container-fluid  p-0">
           
          </header> */}
      {/* PRODUCT DETAILS */}

      <section className="container py-4">
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <p className="fw-500 fs-6">
                  <Link to={`/products/all`} className="text-dark">
                    Products
                  </Link>

                  <Link to={`/products/${categorySlug}`} className="text-dark">
                    {" "}
                    / {productDetails?.category?.name ?? ""}
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              {isLoading ? (
                <div className="col-md-5 text-center">
                  <ShimmerEffect height={"300px"} width={"80%"} />
                </div>
              ) : productDetails &&
                productDetails?.images &&
                productDetails?.images?.length > 0 ? (
                <div className="col-lg-5 ">
                  <div className="row justify-content-center">
                    <div className=" custom-card-shadow py-3 text-center product-details-main-img col-md-10">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${productDetails.images[visibleImage]?.path}`}
                        alt={productDetails.images[visibleImage]?.alternate_text ?? "visible-img"}
                        title={productDetails.images[visibleImage]?.alternate_text}
                        caption={productDetails.images[visibleImage]?.caption}
                        description={productDetails.images[visibleImage]?.description}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-12 my-4">
                      {productDetails?.images?.length > 1 ? (
                        <Slider {...settings}>
                          {productDetails?.images?.map((element, index) => {
                            console.log(element)
                            return (
                              <div
                                className="px-2 cursor-pointer "
                                onClick={() => setVisibleImage(index)}
                              >
                                <div className="background-light  border multiple-img-product p-2 rounded">
                                  <img
                                    key={index + 1}
                                    src={`${
                                      process.env.REACT_APP_API_URL +
                                      "/" +
                                      element?.path
                                    }`}
                                    alt={element?.alternate_text ?? "productImg"}
                                    title={element?.title}
                                    caption={element?.caption}
                                    description={element?.description}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        ""
                      )}

                      <Slider {...settings}></Slider>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-7">
                {isLoading ? (
                  <div>
                    <ShimmerEffect height={"40px"} width={"100%"} />
                    <ShimmerEffect height={"25px"} width={"100%"} />
                    <ShimmerEffect height={"25px"} width={"50%"} />
                  </div>
                ) : (
                  <div className="product-heading-wrapper">
                    <h1 className="section-heading mb-3 ">
                      {productDetails?.name ?? ""}
                    </h1>

                    <p className="font-18 dark-79 fw-500">
                      {productDetails?.introduction ?? ""}
                    </p>
                  </div>
                )}

                {isLoading ? (
                  <ShimmerEffect height={"40vh"} width={"100%"} />
                ) : (
                  <div
                    className="text-justify font-16 table-responsive sunEditor-react"
                    dangerouslySetInnerHTML={{
                      __html: productDetails?.description ?? "",
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* TALK ABOUT PRODUCT SECTION */}
      <section className="container py-5">
        <div className="row justify-content-md-center ">
          <div className="col-md-10">
            <p className="font-primary fw-500 fs-6 mb-0">Enquiry</p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          {enquiryHeadings ? (
            <div className="col-md-10 section-left-border">
              {isLoading ? (
                <ShimmerEffect height={"40px"} width={"40%"} />
              ) : (
                <p className="section-heading mb-2 ">
                  <span>{enquiryHeadings?.enquiry_heading ?? ""}</span>
                </p>
              )}
              {isLoading ? (
                <div className="my-2">
                  <ShimmerEffect height={"25px"} width={"50%"} />
                </div>
              ) : (
                <p className="font-18 dark-79">
                  {enquiryHeadings?.enquiry_sub_heading}
                </p>
              )}
            </div>
          ) : (
            ""
          )}

          <div className="col-md-5 ">
         
            <div className="contact-leftSection-img">
              {/* <img
                src="/images/about_usSection.png"
                alt="enquiry-img"
                className="img-fluid"
              /> */}
              {
                enquiryHeadings?.contact_us_image ?  <img
                src={`${process.env.REACT_APP_API_URL}/${enquiryHeadings?.contact_us_image?.path}`}
                alt={enquiryHeadings?.contact_us_image?.alternate_text ?? "visible-img"}
                title={enquiryHeadings?.contact_us_image?.title}
                caption={enquiryHeadings?.contact_us_image?.caption}
                className="img-fluid"
              /> : ''
              }
             
            </div>
          </div>
          <div className="col-md-5 z-index-top">
            <div className="background-light contact-form-wrapper">
              <Form
                className="login-form"
                form={form}
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Your Name"
                    className="antd-input-custom-style"
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="example@gmail.com"
                    className="antd-input-custom-style"
                  />
                </Form.Item>
                <Form.Item
                  label="Mobile No."
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Mobile no is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Your Mobile no"
                    className="antd-input-custom-style"
                  />
                </Form.Item>

                <Form.Item label="Comment" name="comment">
                  <TextArea
                    placeholder="Type your comment..."
                    rows={5}
                    className="antd-input-custom-style"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="contact-form-btn">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </section>

      {/* <Footerweb /> */}
    </div>
  );
}
