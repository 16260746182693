import { ActionTypes } from "./../constants/ActionTypes";
const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem("loggedIn"))
    ? JSON.parse(localStorage.getItem("loggedIn"))
    : false,
  admin: JSON.parse(localStorage.getItem("adminInfo"))
    ? JSON.parse(localStorage.getItem("adminInfo"))
    : null
};

// const initialState = admin
//   ? { isLoggedIn: true, admin }
//   : { isLoggedIn: false, admin: null };

export const authReduce = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADMIN_LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, admin: payload.admin };
    case ActionTypes.ADMIN_LOGIN_FAILURE:
      return { isLoggedIn: false };
    case ActionTypes.ADMIN_LOGOUT:
      return { isLoggedIn: false };
    default:
      return state;
  }
};
