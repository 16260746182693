import React, { useState } from "react";
import { Form, Input, Button, Table, message } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import CategoryForm from "../../../component/admin_component/product/CategoryForm";
import instance from "../../../api/api_instance";
import { useEffect } from "react";

function ProductCategory() {
  const [isEditForm, setIsEditForm] = useState(false);
  const [editRowData, setEditRowData] = useState({});

  const [productCategories, setProductCategories] = useState([]);

  const fetchProductCategory = () => {
    message.loading({key:"loading",content:"Please wait..."})
    var config = {
      url: `/product-category/all`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            setProductCategories(response.data.data);
            message.destroy("loading")
            // message.success({key:"loading",content:"Data Loaded"})
          } else {
            message.error({key:"loading",content:response.data.message})
          }
        } else {
          message.error({key:"loading",content:response.message})
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({key:"loading",content:error.response.data.message});
        } else {
          message.error({key:"loading",content:error.message});
        }
      });
  };

  const deleteCategory = (id) => {

    if(window.confirm('Are you sure to delete')){
      var config = {
        url: `/product-category/delete/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (
            response.status >= 200 &&
            response.status < 300
          ) {
            if (response.data.status === true) {

              fetchProductCategory()
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  useEffect(() => {
    fetchProductCategory();
  }, []);


  const onEditCateogry = (record) => {
    setIsEditForm(true);
    setEditRowData(record);
  };


  const columns = [
    {
      title: "_id",
      key: "_id",
      dataIndex : "_id",
      hidden : true,
      // render: (text, object, index) => {
      //   return index + 1;
      // },
    },
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
    },
     {
      title: "Slug",
      dataIndex: "category_slug",
      key: "category_slug",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <div key={record._id}>
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => onEditCateogry(record)}
          >
            <FiEdit />
          </Button>

          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteCategory(record._id)}
          >
            <AiOutlineDelete
              className="font-red "
              style={{ cursor: "pointer" }}
            />
          </Button>
        </div>
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Product Category
                <span className="primary-font ms-2">(On website)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-lg-4">
          {isEditForm ? (
            <CategoryForm
              isEditForm={true}
              categoryData={editRowData}
              change={setIsEditForm}
              getData = {fetchProductCategory}
            />
          ) : (
            <CategoryForm isEditForm={false} change={setIsEditForm}  getData = {fetchProductCategory}/>
          )}
        </div>
        <div className="col-lg-8 my-2">
          <div className="card border-0">
              <Table dataSource={productCategories} columns={columns} rowKey="_id" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCategory;
