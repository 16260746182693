import React, { useState } from "react";
import { Table, Button, message, Pagination, Select, Modal } from "antd";
import moment from "moment";

import { AiOutlineDelete, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { BiFilter } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import instance from "../../../api/api_instance";
import { useEffect } from "react";
const { Option } = Select;

export default function Blog() {
  const navigate = useNavigate();

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const page = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

  const [blogData, setBlogData] = useState([]);
  const [modalData, setModalData] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(page);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (record) => {
    setIsModalOpen(true);
    setModalData(record);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteBlog = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/blog/delete/${id}`,
        method: "POST",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              fetchBlogs();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const fetchBlogs = () => {
    message.loading({ key: "loading", content: "Loading..." });
    var config = {
      url: `/blog?page=${currentPage}&&size=10`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            console.log(response?.data?.data)
            setBlogData(response?.data?.data ?? []);
            setTotalData(response?.data?.totalData ?? []);
            console.log(response.data)
            message.success({ key: "loading", content: response.data.message });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, [currentPage]);

  const columns = [
    {
      title: "S_No",
      key: "sno",
      render: (text, object, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Blog Headline",
      dataIndex: "headline",
      key: "headline",
    },
    {
      title: "Publish Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => (
        <p>{moment(text).format("DD-MM-YYYY , hh:mm:ss")}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "200",
      render: (text, record) => (
        <div
          className="d-md-flex justify-content-center align-items-center "
          key={record._id}
        >
          <Button
            className="btn btn-outline-info btn-sm m-1 fw-500"
            onClick={() => showModal(record)}
            // onClick={}
          >
            <AiOutlineEye />
          </Button>
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => navigate(`/admin/blog/edit/${record._id}`)}
          >
            <FiEdit className="text-primary" />
          </Button>

          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteBlog(record._id)}
          >
            <AiOutlineDelete className="font-red" />
          </Button>
        </div>
      ),
    },
  ];

  const handlePagination = (page) => {
    if (page > 0 && page <= totalData) {
      currentPage !== page && navigate(`/admin/blog?page=${page}`);
      setCurrentPage(page);
      //       fetchData();
    }
    // setCurrentPage(pagination);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Manage Blogs
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-3">
        <div className="col-md-12 ">
          <div className="bg-white table-responsive">
            <Table
              rowKey="_id"
              dataSource={blogData}
              columns={columns}
              pagination={false}
            />
            <Pagination
              className="text-center my-3"
              current={currentPage}
              pageSize={10}
              hideOnSinglePage
              onChange={handlePagination}
              total={totalData}
              itemRender={itemRender}
            />
          </div>
        </div>
        <Modal
          title="Blog Details"
          visible={isModalOpen}
          onOk={handleOk}
          footer={false}
          onCancel={handleCancel}
          width={800}
        >
          <div className="row">
            <div className="col-md-12">
              <p className="fw-500 fs-6 font-black">{modalData?.headline ?? ""}</p>
            </div>
          </div>
          {modalData?.image ? (
            <div className="row">
              <div className="col-md-12 blog-modal-img text-center">
                <img
                  src={process.env.REACT_APP_API_URL + "/" + modalData?.image?.path}
                  alt="blog-image" className=""
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {modalData?.description && modalData?.description !== "" ? (
            <div className="row">
              <div
                className="text-justify col-md-12"
                dangerouslySetInnerHTML={{ __html: modalData?.description }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </Modal>
      </div>
    </div>
  );
}
