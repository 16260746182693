import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import instance from "../../../api/api_instance";
import ButtonLoader from "../../ButtonLoader";
const { TextArea } = Input;

export default function EnquiryHeadings() {
  const [form] = Form.useForm();

  const [btnLoading, setBtnLoading] = useState(false);

  const onFinish = (values) => {
    setBtnLoading(true);
    var config = {
      url: `/enquiry/heading/insert`,
      method: "POST",
      data: {
        enquiry_heading: values?.enquiry_heading,
        enquiry_sub_heading: values?.enquiry_sub_heading,
      },
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            get_data();
            setBtnLoading(false);
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error("Something went wrong! try again later");
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
          setBtnLoading(false);
        } else {
          message.error(error?.message);
          setBtnLoading(false);
        }
      });
  };

  const get_data = () => {
    var config = {
      url: `/enquiry/headings`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            form.setFieldsValue({
              enquiry_heading: response?.data?.data?.enquiry_heading,
              enquiry_sub_heading: response?.data?.data?.enquiry_sub_heading,
            });
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  useEffect(() => {
    get_data();
  }, []);
  return (
    <div className="col-12 my-2">
      <div className="card border-0">
        <div className="card-header border-0">
          <p className="mb-0 fw-500 fs-6">Headings</p>
        </div>
        <div className="card-body">
          <Form
            name="contac_heading_form"
            className="login-form"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="enquiry_heading"
                  label="Main Heading"
                  rules={[
                    {
                      required: true,
                      message: "Please input heading!",
                    },
                  ]}
                >
                  <TextArea rows="3" placeholder="Enter Heading" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="enquiry_sub_heading"
                  label="Sub Heading"
                  rules={[
                    {
                      required: true,
                      message: "Please input sub heading!",
                    },
                  ]}
                >
                  <TextArea rows="3" placeholder="Enter Sub Heading" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-2">
                <Form.Item className="text-end">
                  <Button
                    htmlType="submit"
                    className="form-btn-style"
                    disabled={btnLoading ? true : false}
                  >
                    {btnLoading ? (
                      <span className="me-2">
                        <ButtonLoader />
                      </span>
                    ) : (
                      ""
                    )}
                    Save
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
