import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Input, DatePicker, Modal, Image, message, Tooltip } from "antd";
import { MdOutlineEmail, MdDateRange } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { Upload } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import axios from "axios";
import ImageSeoModal from "../../component/ImageSeoModal";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function AdminProfile() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");


  var isLoggedIn = localStorage.getItem("loggedIn")

  var data = null
  if (isLoggedIn) {
    data = JSON.parse(localStorage.getItem("adminInfo"))
  }
  else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

  const adminData = useSelector(state => state.auth.admin)
  const onFinish = (values) => {
    message.loading({ content: "Loading...", key: "loading" })
    let formData = new FormData();
    formData.append("name", values.name.trim());
    formData.append("mobile", values.mobile);
    formData.append("email", values.email.trim());
    // formData.append("dob", values.dob ? moment(values.dob).format('DD-MM-YYYY') : "");
    formData.append("id", adminData._id);

    if (fileList.length > 0) {
      formData.append("profile_img", fileList[0].originFileObj);
    }
    axios.post(`${process.env.REACT_APP_API_URL}/adminapi/edit-profile`, formData).then((response) => {
      if (response?.status >= 200 && response?.status < 300){
        if (response.data.status) {
          dispatch({
            type: ActionTypes.ADMIN_LOGIN_SUCCESS,
            payload: { admin: response.data.data }
          });
          localStorage.setItem('adminInfo', JSON.stringify(response.data.data))
          setFileList([])
          message.success({ content: response.data.message, key: "loading" })
          // alert(res.data.message)
        }
        else {
          message.error({ content: response.data.message, key: "loading" })
        }
      }else{
        message.error("Something went wrong! try again later")
      }
    }).catch((error) => {
      // Server can't be reached!
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error(error?.message)
      }
    });

  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };


  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    form.setFieldsValue({
      name: data.name ? data.name : "",
      mobile: data.mobile ? data.mobile : "",
      email: data.email ? data.email : "",
      dob: data.dob ? moment(data.dob) : "",
    })

  }, [form, data])

  return (
    <div className="container">
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <div className="row">
        <div className="col-md-3 mt-3 px-3 ">
          <div className="form-card h-100 bg-white">
            <div className="profile-card-img">
              <img src={adminData?.profilePic ? process.env.REACT_APP_API_URL + "/" + adminData?.profilePic : "/images/Default-registration-profile.png"}  className="img-fluid"  />
            </div>
            <div className="row">
              <div className="col-12">
                <p className="fs-5 fw-500 text-center font-black mb-0 text-capitalize">{adminData.role ? adminData.role : null}</p>
                <p className="fs-6 mb-1 mt-2 font-black">
                  <span className="font-orange">
                    <BiUser />
                  </span>
                  <span className="text-capitalize">{adminData.name ? adminData.name : null}</span>
                </p>
                <p className="fs-6 mb-1 mt-2 font-black " style={{ wordBreak: "break-all" }}>
                  <span>
                    <MdOutlineEmail className="font-orange" />{" "}
                  </span>
                  {adminData.email ? adminData.email : null}
                </p>
                <p className="fs-6 mb-1 mt-2 font-black">
                  <span>
                    <AiOutlinePhone className="font-orange" />{" "}
                  </span>
                  {adminData.mobile ? adminData.mobile : null}
                </p>
                {/* <p className="fs-6 mb-1 mt-2 font-black">
                  <span>
                    {" "}
                    <MdDateRange className="font-orange" /> {adminData.dob ? moment(adminData.dob).format('DD-MM-YYYY') : null}
                  </span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 mt-3">
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Edit Profile</p>
            </div>
            <div>
              <Form
                form={form}
                name="login"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-12 d-flex">
                    {adminData.profilePic ?
                      <div className="me-3">
                        <Image src={process.env.REACT_APP_API_URL + "/" + adminData?.profilePic} style={{ objectFit: "cover", objectPosition: "10% 20%" }} width={100} height={100}  />
                      </div>
                      : null}
                    <div>
                      <Upload
                        accept="image/*"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={onChange}
                        beforeUpload={() => false}
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Item
                      label="User Name"
                      name="name"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_ ]+$/,
                          message: "User name can contain alphanumeric characters only!"
                        },
                        {
                          required: true,
                          message: "Please input user name!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter Username " />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Mobile"
                      name="mobile"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        },
                        {
                          required: true,
                          message: "Please input your mobile number!"
                          // whitespace:true,
                        }
                      ]}
                    >
                      <Input type="number" placeholder="Enter Your Mobile" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row align-items-end ">
                  <div className="col-md-6 ">
                    <Tooltip placement="bottomRight" title="Email not editable">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please input valid email!"
                          },
                          {
                            required: true,
                            message: "Please input your email!"
                          }
                        ]}
                      >
                        <Input placeholder="Enter Your Email" disabled />
                      </Form.Item>
                    </Tooltip>
                  </div>
                  <div className="col-md-6 text-end">
                    <Form.Item>
                      <Button htmlType="submit" className="form-btn border-0">
                        Update
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
