import React, { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { BiPhoneCall, BiChevronDown } from "react-icons/bi";
import { HiDownload } from "react-icons/hi";
import { RiMenu5Fill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { Link, NavLink, useNavigate } from "react-router-dom";
import instance from "../../api/web_instance";
import { message, Modal, Select } from "antd";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import TranslateComponent from "../TranslateComponent";
import {IoLogoWhatsapp} from "react-icons/io"
const { Option } = Select;

export default function Navbarweb({setContactNo}) {
  const geoUrl =
    "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
  const [navData, setNavData] = useState({});
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapHightlight, setMapHightLight] = useState({ long: 0, lat: 0 });
  const navigate = useNavigate();

  // SEARCH DATA
  const [searchQry, setSearchQry] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isVisible, setisVisible] = useState(false);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // GET NAVIGATIN DATA
  const get_data = () => {
    var config = {
      url: `/navigation-data`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setNavData(response?.data?.data?.navigation);
            setContactNo(response?.data?.data?.navigation?.mobile)
            setSocialMediaData(response?.data?.data?.social_media);
            setProductCategories(response?.data?.data?.product_category);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // SEARCH PRODUCT

  const getSearchProducts = () => {
    var config = {
      url: `/product-name?search=${searchQry}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            if (response?.data?.data?.length > 0) {
              setSearchData(response.data.data);
            }
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  useEffect(() => {
    get_data();
  }, []);

  const onChange = (value, e) => {
    navigate(`/product-details/${e?.dataSlug}`, { state: { id: e?.value } });
  };

  useEffect(() => {
    getSearchProducts();
  }, [searchQry]);

  return (
    <nav className="background-light position-sticky navigation ">
     <div className="bottom-wa-icon">
        <a href={`https://wa.me/+91${navData?.mobile}`} data-action="share/whatsapp/share" target="_blank">
          <IoLogoWhatsapp/> +91{navData?.mobile}
          {/* <IoLogoWhatsapp/> +916377936095 */}
        </a>
      </div>
      <div className="navigation-top">
        <div className="container">
          <div className="row justify-content-md-between text-center align-items-center py-2">
            <div className="col-md-5">
              <ul className="list-unstyled d-flex mb-0 justify-content-center justify-content-md-start">
                <li className="text-white font-14 px-3 top-navigation-contact-details">
                  <span className="me-1 fs-6">
                    <MdOutlineMailOutline />
                  </span>
                  <a
                    href={`mailto:${navData?.email}`}
                    className="text-white text-lowercase"
                  >
                    {navData?.email ?? ""}
                  </a>
                </li>
                <li className="text-white font-14 px-3 ">
                  <span className="me-1 fs-6">
                    <BiPhoneCall />
                  </span>
                  <a href={`tel:+91${navData?.mobile}`} className="text-white">
                    {navData?.mobile ? <span>+91{navData?.mobile}</span> : ""}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-5 responsive-top-menu-social-media justify-content-md-end">
              <ul className="list-unstyled my-2 d-flex mb-0  align-items-end justify-content-center justify-content-md-end nav-right-ul ">
                {socialMediaData && socialMediaData?.length > 0
                  ? socialMediaData.map((element, index) => {
                      return (
                        <li key={index + 1}>
                          <a
                            href={`${
                              element?.social_link ? element?.social_link : "/"
                            }`}
                            rel="noreferrer"
                            target="_blank"
                            className="mb-0 text-white"
                            dangerouslySetInnerHTML={{
                              __html: element.social_icon,
                            }}
                          ></a>
                        </li>
                      );
                    })
                  : ""}

                <li>
                  <TranslateComponent />
                </li>
              </ul>
              <div className="text-end cursor-pointer" onClick={showModal}>
                <img src="/images/india.png" alt="countryImg" />
                <span className=" ms-2 text-white font-12">IND</span>
                <span className="fs-5 text-white">
                  <BiChevronDown />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-navigation container align-items-center">
        <div>
          <Link to="/" preventScrollReset={false}>
            {navData?.logo?._id ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${navData?.logo?.path}`}
                alt={navData?.logo?.alternate_text ?? "Logo"}
                title={navData?.logo?.title}
                caption={navData?.logo?.caption}
                description={navData?.logo?.description}
                className="img-fluid"
              />
            ) : (
              <img src="/images/logo.png" alt="Logo" />
            )}
          </Link>
        </div>
        <div className="navigation-menu-container">
          <div className="d-flex">
            <div className="navbar-menu notranslate">
              <ul className="list-unstyled d-flex align-items-center  mb-0">
                <li>
                  <NavLink
                    to="/"
                    preventScrollReset={true}
                    className={({ isActive }) =>
                      isActive
                        ? "active-nav-menu main-nav-hover "
                        : "inactive main-nav-hover"
                    }
                  >
                    Home
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink
                    preventScrollReset={true}
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive
                        ? "active-nav-menu main-nav-hover"
                        : "inactive main-nav-hover"
                    }
                  >
                    About
                  </NavLink>
                </li>
                <li className="position-relatvie productLink">
                  <NavLink
                    preventScrollReset={true}
                    to="/products"
                    className={({ isActive }) =>
                      isActive
                        ? "active-nav-menu  main-nav-hover"
                        : "inactive main-nav-hover"
                    }
                    onClick={(e) => e.preventDefault()}
                  >
                    <span
                      className="d-block"
                      onClick={() => navigate(`/products/all`)}
                    >
                      Product
                    </span>

                    <ul className="product-dropdown px-0 position-absolute">
                      {productCategories && productCategories?.length > 0
                        ? productCategories?.map((element, index) => {
                            // var convertToLower = element.name.toLowerCase().trim();
                            // var slug = convertToLower.split(/[' '/.()]+/).join("-");
                            // var some = encodeURI(slug);
                            return (
                              <li key={index + 1} className="border-bottom">
                                <p
                                  className="dark-79"
                                  // to = {`/products/${element?.category_slug}`}
                                  onClick={() =>
                                    navigate(
                                      `/products/${element?.category_slug}`
                                    )
                                  }
                                >
                                  {element?.name}
                                </p>
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    preventScrollReset={true}
                    to="/contact-us"
                    className={({ isActive }) =>
                      isActive
                        ? "active-nav-menu main-nav-hover"
                        : "inactive main-nav-hover"
                    }
                  >
                    Contact
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    preventScrollReset={true}
                    to="/blog"
                    className={({ isActive }) =>
                      isActive
                        ? "active-nav-menu main-nav-hover"
                        : "inactive main-nav-hover"
                    }
                  >
                    Blog
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="right-menu navbar-menu">
            <ul className="list-unstyled d-flex align-items-center mb-0">
              <li id="search-li">
                {/* <HiSearch className="fs-5" /> */}
                <Select
                  showSearch
                  onChange={(value, e) => onChange(value, e)}
                  onSearch={(e) => {
                    setSearchQry(e);
                  }}
                  style={{ width: 200 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  className="lksadjf"
                  getPopupContainer={() => document.getElementById("search-li")}
                >
                  {searchData && searchData?.length > 0
                    ? searchData.map((element, index) => {
                        return (
                          <Option
                            key={index + 1}
                            dataSlug={element?.product_slug}
                            value={element?._id}
                          >
                            {element?.name}
                          </Option>
                        );
                      })
                    : ""}
                  {/* {
                  searchData
                 } */}
                </Select>
              </li>
              <li>
                <Link to="/contact-us" className="top-nav-btn">
                  Quick Quote
                </Link>
              </li>
              <li className="ms-2">
                {navData?.broucher ? (
                  <a
                    href={`${process.env.REACT_APP_API_URL}/${navData?.broucher}`}
                    target="_blank"
                    rel="noreferrer"
                    className="top-nav-btn"
                  >
                    <HiDownload className="fw-bold fs-5" /> Brochure
                  </a>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* RESPONSIVE NAVBAR */}
      <div className="responsive-nav">
        <div
          className={` container d-flex align-items-center justify-content-between px-3 py-2`}
          onClick={() => setisVisible(!isVisible)}
        >
          <Link to="/" onClick={() => setisVisible(!isVisible)}>
            {navData?.logo ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${navData?.logo?.path}`}
                alt={navData?.logo?.alternate_text ?? "Logo"}
                title={navData?.logo?.title}
                caption={navData?.logo?.caption}
                description={navData?.logo?.description}
                className="img-fluid"
              />
            ) : (
              <img src="/images/logo.png" alt="Logo" className="img-fluid" />
            )}
          </Link>
          <RiMenu5Fill className="text-dark fs-4" />
        </div>
        <div
          className={`navbar-menu text-center ${
            isVisible ? "nav-visible" : "nav-hidden"
          }`}
        >
          <ul className="list-unstyled mb-0 notranslate">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active-nav-menu" : "inactive"
                }
                onClick={() => setisVisible(!isVisible)}
              >
                Home
              </NavLink>{" "}
            </li>
            <li className="position-relatvie productLink">
              <NavLink
                to="/products/all"
                className={({ isActive }) =>
                  isActive
                    ? "active-nav-menu  main-nav-hover"
                    : "inactive main-nav-hover"
                }
                onClick={(e) => e.preventDefault()}
              >
                <span className="">Products</span>
                <ul className="product-dropdown px-0 position-absolute">
                  {productCategories && productCategories?.length > 0
                    ? productCategories?.map((element, index) => {
                        // var convertToLower = element.name.toLowerCase().trim();
                        // var slug = convertToLower.split(/[' '/.()]+/).join("-");
                        // var some = encodeURI(slug);
                        return (
                          <li key={index + 1} className="border-bottom">
                            <p
                              className="dark-79"
                              // to = {`/products/${element?.category_slug}`}
                              onClick={() => {
                                navigate(`/products/${element?.category_slug}`);
                                setisVisible(!isVisible);
                              }}
                            >
                              {element?.name}
                            </p>
                            {/* <Link
                              to={`/products/${element?._id}/${some}`}
                              state={{
                                id: element?._id,
                                name: element?.name,
                              }}
                              onClick={() => setisVisible(!isVisible)}
                            >
                              {element?.name}
                            </Link> */}
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  isActive ? "active-nav-menu" : "inactive"
                }
                onClick={() => setisVisible(!isVisible)}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive ? "active-nav-menu" : "inactive"
                }
                onClick={() => setisVisible(!isVisible)}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                preventScrollReset={true}
                to="/blog"
                className={({ isActive }) =>
                  isActive ? "active-nav-menu" : "inactive"
                }
                onClick={() => setisVisible(!isVisible)}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <Select
                showSearch
                onChange={(value, e) => onChange(value, e)}
                onSearch={(e) => {
                  setSearchQry(e);
                }}
                style={{ maxWidth: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
              >
                {searchData && searchData?.length > 0
                  ? searchData.map((element, index) => {
                      return (
                        <Option
                          key={index + 1}
                          dataSlug={element?.product_slug}
                          value={element?._id}
                        >
                          {element?.name}
                        </Option>
                      );
                    })
                  : ""}
              </Select>
            </li>
            <li className="translate">
              <Link
                to="/contact-us"
                className="top-nav-btn"
                onClick={() => setisVisible(!isVisible)}
              >
                Quick Quote
              </Link>
            </li>
            <li className="translate">
              {navData?.broucher ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/${navData?.broucher}`}
                  target="_blank"
                  rel="noreferrer"
                  className="top-nav-btn"
                >
                  <HiDownload className="fw-bold fs-5" /> Brochure
                </a>
              ) : (
                ""
              )}
            </li>
          </ul>
        </div>
      </div>

      <marquee className="mb-n5 bg-white">
        <div>
          <p className="mb-0 fw-500 font-secondary px-3">#COMMITMENT</p>
          <p className="mb-0 fw-500 font-secondary px-3">#PERFORMANCE</p>
          <p className="mb-0 fw-500 font-secondary ps-4 pe-3">#SATISFACTION</p>
        </div>
      </marquee>

      

      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={"100vw"}
        closable={false}
        centered={true}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span>
                <GrClose
                  className="fw-bold cursor-pointer fs-5 mb-2"
                  onClick={handleCancel}
                />{" "}
              </span>
              <span className="ms-3 mb-0 fs-5 fw-500 dark-79">
                OLIVER RUBBER GLOBAL
              </span>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <p className="fong-18 fw-500">INTERNATIONAL</p>
                    <p className="font-12 mb-1">
                      <a
                        href="https://oliverllp.com"
                        target="_blank"
                        className="dark-79 fw-500"
                      >
                        ORI GLOBAL - ENGLISH
                      </a>
                    </p>
                  </div>
                  <div className="my-3">
                    <p className="fong-18 fw-500">AFRICA</p>
                    <p className="font-12 mb-1">
                      <a
                        href="https://oliverllp.co.za"
                        target="_blank"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 23, lat: -30 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        SOUTH AFRICA - ENGLISH
                      </a>
                    </p>
                  </div>
                  <div className="my-3">
                    <p className="fong-18 fw-500">AMERICA</p>
                    <p className="font-12 mb-1 text-uppercase">
                      <a
                        href="https://oliverllp.com"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: -96, lat: 37 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        USA - ENGLISH
                      </a>
                    </p>
                    <p className="font-12 mb-1 text-uppercase">
                      <a
                        href="https://oliverllp.cl"
                        target="_blank"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: -71, lat: -35 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        CHILE - English
                      </a>
                    </p>
                  </div>
                  <div className="my-3">
                    <p className="fong-18 fw-500">ASIA</p>
                    <p className="font-12 mb-1 text-uppercase">
                      <a
                        href="https://oliverllp.com"
                        target="_blank"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 78, lat: 20 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        INDIA - English/Hindi
                      </a>
                    </p>
                    <p className="font-12 mb-1 text-uppercase">
                      <a
                        href="https://oliverllp.id"
                        target="_blank"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 117, lat: -4.12 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        INDONESIA - Indonesian
                      </a>
                    </p>
                    <p className="font-12 mb-1 text-uppercase">
                      <a
                        href="https://oliverllp.my"
                        target="_blank"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 101.7, lat: 3.22 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        MALAYSIA - MALASIAN
                      </a>
                    </p>
                  </div>
                  <div className="my-3">
                    <p className="fong-18 fw-500">MIDDLE EAST</p>
                    <p className="font-12 mb-1 text-uppercase">
                      <a
                        href="https://oliverllp.ae"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 53.8, lat: 23.5 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        UAE - ARABIC
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="fong-18 mb-2 fw-500">EUROPE</p>
                  <ul className="list-unstyled">
                    <li className="font-14 my-1">
                      <a
                        href="https://oliverllp.nl/"
                        target="_blank"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 5.6, lat: 51.9 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        NETHERLANDS - DUTCH
                      </a>
                    </li>
                    {/* <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 9, lat: 51 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Germany - OLIVER RUBBER Automotive
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a href="/" className="dark-79 fw-500">
                        Germany - OLIVER RUBBER Borna
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 9, lat: 51 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Germany - OLIVER RUBBER Desdorf
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 9, lat: 51 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Germany - OLIVER RUBER Elbe
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 9, lat: 51 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Germany - OLIVER RUBBER Vulkanisiertechnik
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 4, lat: 50.83333 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Belgium - Automotive Dutch/FRENCH
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 4, lat: 50.83333 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Belgium - Industry Dutch/FRENCH
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 10, lat: 56 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Denmark - Dansk
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: -3.70034, lat: 40.416691 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        España - Español
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 26, lat: 64 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Finland - Suomi
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 2, lat: 46 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        France - Français
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 12.8333, lat: 42.8333 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Italy - Italiano
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 5.75, lat: 52.5 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Netherlands - Automotive Dutch/English
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 5.75, lat: 52.5 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Netherlands - Industry Dutch/English
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: 8, lat: 47 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        Switzerland - Deutsch
                      </a>
                    </li>
                    <li className="font-14 my-1">
                      <a
                        href="/"
                        className="dark-79 fw-500"
                        onMouseEnter={() => {
                          setMapHightLight({ long: -2, lat: 54 });
                        }}
                        onMouseLeave={() => {
                          setMapHightLight({ long: 0, lat: 0 });
                        }}
                      >
                        UK & Ireland - English
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <ComposableMap
                // projection="geoMercator"
                projectionConfig={{
                  // rotate: [-60.0, -40.0, 0],
                  center: [0, 0],
                  scale: 200,
                }}
              >
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      //  console.log(geo)
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: "#AED6F1",
                          },
                          hover: {
                            fill: "#5D6D7E",
                          },
                          pressed: {
                            fill: "#E42",
                          },
                        }}
                      />
                    ))
                  }
                </Geographies>
                {mapHightlight && mapHightlight?.long && mapHightlight?.lat ? (
                  <Marker
                    coordinates={[mapHightlight?.long, mapHightlight?.lat]}
                    fill="#F53"
                  >
                    <circle r={5} fill="#F53" x={0} y={0} />
                  </Marker>
                ) : (
                  ""
                )}
              </ComposableMap>
            </div>
          </div>
        </div>
      </Modal>
    </nav>
  );
}
