import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Space, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import instance from "../../../api/api_instance";
const { Option } = Select;

export default function LanguageComponent() {
  const [form] = Form.useForm();

  // const [selectedLanguages, setSelectedLanguages] = useState(["en", "hi"]);
  const [laguageOptions, setLanguageOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const onFinish = (values) => {
    setBtnLoading(true);
    const data = {
      languages: values?.languages,
      domains: values?.domains,
    };

    var config = {
      url: `/language/insert`,
      method: "POST",
      data: data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            // get_data();
            setBtnLoading(false);
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error({
            key: "loading",
            content: "Something went wrong! try again later",
          });
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({
            key: "loading",
            content: error?.response?.data?.message,
          });
          setBtnLoading(false);
        } else {
          message.error({ key: "loading", content: error?.message });
          setBtnLoading(false);
        }
      });
  };

  const get_data = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/languages`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            // setSelectedLanguages(response?.data?.data?.languages);
            form.setFieldsValue({
              domains: response?.data?.data?.domains,
              languages: response?.data?.data?.languages,
            });
            message.success({ key: "loading", content: "Data Loaded." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          message.error({
            key: "loading",
            content: error?.response?.data?.message,
          });
        } else {
          message.error({ key: "loading", content: error?.message });
        }
      });
  };

  // const handleChange = (value, event) => {
  //   var languages = [];
  //   event.forEach((element, index) => {
  //     languages.push({
  //       code: element?.value,
  //       name: element?.dataName,
  //     });
  //   });

  //   setSelectedLanguages(["en", "hi"]);
  // };

  const get_languages = () => {
    axios.get(`/assets/language.json`).then((res) => {
      var countryData = [];
      res.data.forEach((element, index) => {
        countryData.push(
          <Option
            key={index + 1}
            value={element.code}
            label={element.name}
            name={element?.name ? element?.name : ""}
            // datalang={element.longitude}
            // datalatitude={element.latitude}
          >
            <div className="demo-option-label-item">
              {element.name}
              <span role="img" aria-label={element.code}>
                ({element.code})
              </span>
            </div>
          </Option>
        );
      });
      setLanguageOptions(countryData);
    });
  };

  const onSearch = (values) =>{
    console.log(values)
  }

  useEffect(() => {
    get_data();
    get_languages();
  }, []);
  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">Language</p>
      </div>
      <div className="card-body">
        <Form
          name="aboutus_form"
          className="login-form"
          initialValues={{ remember: true }}
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-12" id="language-select-menu">
              <Form.Item
                label="Select languages"
                name="languages"
                rules={[{ required: true, message: "Select languages!" }]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="select languages"
                  // defaultValue={["india"]}
                  // onChange={(value, event) => handleChange(value, event)}
                  className="country-select"
                  getPopupContainer={() =>
                    document.getElementById("language-select-menu")
                  }
                  optionLabelProp="label"
                >
                  {laguageOptions}
                </Select>
              </Form.Item>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <Form.List name="domains">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        // direction="vertical"
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "url"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing url!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter url" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "default_language"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing default language",
                            },
                          ]}
                        >
                          <Select
                            // defaultValue="en"
                            showSearch
                            placeholder="Select default language"
                            onSearch={onSearch}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {laguageOptions}
                          </Select>
                        </Form.Item>

                        <div className="text-start">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        className="d-inline-block"
                        // block
                        icon={<PlusOutlined />}
                      >
                        Add New Domain
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div> */}
          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
