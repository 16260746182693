import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Upload, message, Button, Image } from "antd";
import instance from "../../../api/api_instance";
import { useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import ButtonLoader from "../../ButtonLoader";
import ImageSeoModal from "../../ImageSeoModal";

export default function Partners() {
  const [form] = Form.useForm();
  const [partnerImages, setPartnerImages] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const onFinish = (values) => {
    let formData = new FormData();
    if (
      values?.partner_img?.fileList &&
      values?.partner_img?.fileList?.length > 0
    ) {
      values?.partner_img?.fileList?.forEach((element) => {
        formData.append("partner_img", element?.originFileObj);
      });

      formData.append("prevImgs", partnerImages);

      var config = {
        url: `/upload-partners`,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      instance(config)
        .then((response) => {
          if (response?.status >= 200 && response?.status < 300) {
            if (response.data.status) {
              get_all_partners();
              message.success({
                content: response.data.message,
                key: "loading",
              });
              setBtnLoading(false);
              form.resetFields();

              // alert(res.data.message)
            } else {
              message.error({ content: response.data.message, key: "loading" });
              setBtnLoading(false);
            }
          } else {
            message.error("Something went wrong! try again later");
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          // Server can't be reached!
          if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message);
            setBtnLoading(false);
          } else {
            message.error(error?.message);
            setBtnLoading(false);
          }
        });
    } else {
      message.error("Upload client images before submit!");
      setBtnLoading(false);
    }
  };

  const deletePartner = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/delete-partner/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              get_all_partners();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error.message);
          }
        });
    }
  };

  // FETCH TESTIMONIALS
  const get_all_partners = () => {
    message.loading({ key: "loading", content: "Loading..." });
    var config = {
      url: `/partners`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            message.success({ key: "loading", content: "Data Loaded." });
            setPartnerImages(response.data.data);
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  useEffect(() => {
    get_all_partners();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card border-0">
          <div className="card-body ">
            <div className="row align-items-end">
              <div className="col-auto">
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  form={form}
                  layout={"vertical"}
                  onFinish={onFinish}
                  requiredMark={false}
                >
                  <Form.Item label="Client Images" name="partner_img">
                    <Upload
                      listType="picture-card"
                      accept="image/*"
                      multiple={true}
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item className="text-start">
                    <Button
                      htmlType="submit"
                      className="form-btn-style"
                      disabled={btnLoading ? true : false}
                    >
                      {btnLoading ? (
                        <span className="me-2">
                          <ButtonLoader />
                        </span>
                      ) : (
                        ""
                      )}
                      Upload
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="row justify-content-center justify-content-md-start">
              {partnerImages && partnerImages?.length > 0
                ? partnerImages.map((element, index) => {
                    return (
                      <div className="col-md-2  my-2 " key={index + 1}>
                        <div className="border partner-image-gallary p-3 rounded">
                          <img
                            src={`${
                              process.env.REACT_APP_API_URL +
                              "/" +
                              element?.path
                            }`}
                            alt=""
                            className="img-fluid d-block"
                          />

                          <div className="d-flex">
                            <ImageSeoModal imageId={element?._id} />
                            <button
                              className="btn btn-danger btn-sm my-2 ms-2 fw-500"
                              onClick={() => deletePartner(element?._id)}
                            >
                              <AiOutlineDelete style={{ cursor: "pointer" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
