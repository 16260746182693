import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../api/web_instance";

export default function Footerweb() {
  const [footerData, setFooterData] = useState({});
  const [footerAddress, setFooterAddress] = useState({});
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [productList, setProductList] = useState([]);

  const get_data = () => {
    var config = {
      url: `/footer-data`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setFooterData(response?.data?.data?.footerData);
            setFooterAddress(response?.data?.data?.address);
            setSocialMediaData(response?.data?.data?.social_links);
            setProductList(response?.data?.data?.products);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  useEffect(() => {
    get_data();
  }, []);

  return (
    <footer
      className="shadow-none web-footer"
    > 
    {/* style={{
        backgroundImage: footerData?.footer_bg
          ? `url(${process.env.REACT_APP_API_URL}/${footerData?.footer_bg})`
          : `url('/images/footerbg.png')`,
      }} */}
      <div className="footer-bg-img">
        <img
          src={`${process.env.REACT_APP_API_URL}/${footerData?.footer_bg?.path}`}
          alt={footerData?.footer_bg?.alternate_text ??  "footer-bg"}
          title={footerData?.footer_bg?.alternate_text ??  ""}
          caption={footerData?.footer_bg?.caption ?? ''}
          description={footerData?.footer_bg?.description ?? ''}
        />
      </div>
      <div className="footer-overlap">
        <div className="container pt-5">
          {/* <div className="row">
          <div className="col-lg-10 mx-auto">
            <Link to="/">
              {footerData?.logo ? (
                <img
                  src={`${process.env.REACT_APP_API_URL}/${footerData?.logo}`}
                  alt="Logo"
                />
              ) : (
                <img src="/images/logo.png" alt="Logo" />
              )}
            </Link>
          </div>
        
        </div> */}
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <Link to="/">
                {footerData?.logo?._id ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${footerData?.logo?.path}`}
                    alt={`${footerData?.logo?.alternate_text ?? "Logo"}`}
                    title={footerData?.logo?.title?? ''}
                    caption={footerData?.logo?.caption ?? ''}
                    description={footerData?.logo?.description ?? ''}
                  />
                ) : (
                  <img src="/images/logo.png" alt="Logo" />
                )}
              </Link>
              <p className="text-justify text-white font-16">
                {footerData?.footer_content ?? ""}
              </p>
              <ul className="footer-social-icons list-unstyled">
                {socialMediaData && socialMediaData?.length > 0
                  ? socialMediaData.map((element, index) => {
                      return (
                        <li key={index + 1}>
                          <a
                            href={`${
                              element?.social_link ? element?.social_link : "/"
                            }`}
                            target="_blank"
                            rel="noreferrer"
                            className="mb-0 text-white font-16"
                            dangerouslySetInnerHTML={{
                              __html: element.social_icon,
                            }}
                          ></a>
                        </li>
                      );
                    })
                  : ""}
                {/* <li><span><AiOutlineTwitter className="text-white fs-6"/></span></li>
                  <li><span><BsFacebook className="text-white fs-6"/></span></li>
                  <li><span><BsInstagram className="text-white fs-6"/></span></li> */}
              </ul>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6 mt-3">
              <div className="row">
                <div className="col-lg-4">
                  <p className="fw-500 font-18 text-white">CONTACT US</p>
                  <p className="text-white mb-1 font-16 fw-bold">
                    {footerAddress?.location ?? ""}
                  </p>
                  <p className="text-white font-16">
                    {footerAddress?.full_address}
                  </p>
                  <p className="text-white mb-0 font-16">
                    <a
                      href={`tel:${footerData?.mobile}`}
                      className="text-white"
                    >
                      {footerData?.mobile ? "+91-" + footerData?.mobile : ""}
                    </a>
                  </p>
                  <p className="text-white font-16 ">
                    <a
                      href={`mailto:${footerData?.email}`}
                      className="text-white"
                    >
                      {footerData?.email ?? ""}
                    </a>
                  </p>
                </div>
                {productList && productList?.length > 0 ? (
                  <div className="col-lg-5">
                    <p className="fw-500 text-white font-18">OUR PRODUCTS</p>
                    <ul className="list-unstyled line-height-20">
                      {productList?.map((product, index) => {
                        return (
                          <li key={index + 1} className="footer-product-hover">
                            {" "}
                            <Link
                              to={
                                product?.product_slug
                                  ? `/product-details/${product?.product_slug}`
                                  : "/"
                              }
                              state={{ id: product?._id }}
                              className="font-16 text-white line-clamp"
                            >
                              {product?.name ?? ""}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}{" "}
                <div className="col-lg-3">
                  <p className="fw-500 text-white font-18">QUICK LINKS</p>
                  <ul className="list-unstyled footer-quick-links">
                    {footerData?.quick_links &&
                    footerData?.quick_links?.length > 0
                      ? footerData?.quick_links?.map((element, index) => {
                          return (
                            <li key={index + 1}>
                              <a
                                href={`${
                                  element?.link_url ? element?.link_url : ""
                                }`}
                                className="text-white font-16"
                              >
                                {element?.link_title}
                              </a>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-lg-10 border-top pt-3">
              <p className="text-white text-center">
                © Copyright - Oliver Rubber Industries LLP{" "}
                {new Date().getFullYear()} | Design And Developed By -
                <a
                  className="text-white fw-bold text-decoration-underline"
                  href="https://techoniqfusion.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Techoniq Fusion It Solutions PVT. LTD
                </a>{" "}
                - All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
