import React, { useEffect, useRef, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Empty, message } from "antd";
import instance from "../../api/web_instance";
import moment from "moment";
import ShimmerEffect from "../../component/ShimmerEffect";
import { Helmet } from "react-helmet";

function BlogPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const [seoData, setSeoData] = useState({});
  const get_data = () => {
    setIsLoading(true);
    var config = {
      url: `/blog?size=10&&page=${page}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            if (blogData && blogData.length === 0) {
              setSeoData(response?.data?.seo_data);
            }
            setBlogData((prev) => [...prev, ...response.data.data]);
            setIsLoading(false);
          } else {
            message.error({ key: "loading", content: response.data.message });
            setIsLoading(false);
          }
        } else {
          message.error({ key: "loading", content: response.message });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
        setIsLoading(false);
      });
  };
  function handleScroll(e) {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 >=
      e.target.documentElement.scrollHeight - 500
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    get_data();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  if (blogData && blogData?.length > 0) {
    return (
      <div className="container py-5">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {seoData?.head_title ? seoData.head_title : "Oliver Rubber"}
          </title>
          <meta
            name="title"
            content={seoData?.meta_title ? seoData.meta_title : "Oliver Rubber"}
          ></meta>
          <meta
            name="description"
            content={
              seoData?.meta_description
                ? seoData.meta_description
                : "Oliver Rubber"
            }
          ></meta>
          <meta
            name="keyword"
            content={seoData?.meta_keyword ? seoData.meta_keyword : ""}
          ></meta>
          <link rel="canonical" href="https://oliverllp.com/blog" />
        </Helmet>

        <div className="row my-4">
          {blogData && blogData.length > 0 ? (
            blogData.map((element, key) => {
              return (
                <div key={key + 1} className="col-md-6 col-lg-4">
                  <div className="blog-grid-card">
                    <div className="blog-grid-overlap"></div>
                    {
                      element?.image?._id ? 
                      <img
                      src={`${process.env.REACT_APP_API_URL}/${element?.image?.path}`}
                      alt={`${element?.image?.alternate_text ?? "blog-image"}`}
                      title={element?.image?.title}
                      caption={element?.image?.caption}
                      description={element?.image?.description}
                      className=""
                    /> : ""
                    }
                   
                  </div>
                  <div className="blog-grid-content mt-2">
                    {key === 0 ? (
                      <h1 className="text-justify fw-bold dark-font font-20">
                        {element?.headline}
                      </h1>
                    ) : (
                      <p className="text-justify fw-bold dark-font font-20">
                        {element?.headline}
                      </p>
                    )}

                    <div
                      className="text-justify font-18 blog-content-clamp table-responsive sunEditor-react"
                      dangerouslySetInnerHTML={{
                        __html: element?.description,
                      }}
                    ></div>
                  </div>
                  <div className="blog-grid-bottom mt-4 mb-3 d-flex justify-content-between">
                    <p className="fw-500">
                      {moment(element.createdAt).fromNow()}
                    </p>
                    <Link
                      to={`/blog/${element?.slug}`}
                      className="font-primary fw-500"
                    >
                      Read More <FiArrowUpRight />
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-md-12 mt-5 pt-5">
              <Empty />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="container py-5">
        {isLoading ? (
          <div className="row">
            <div className="col-md-6">
              <ShimmerEffect height={"400px"} width={"100%"} />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <ShimmerEffect height={"200px"} width={"100%"} />
                </div>
                <div className="col-md-6">
                  {" "}
                  <ShimmerEffect height={"200px"} width={"100%"} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <ShimmerEffect height={"200px"} width={"100%"} />
                </div>
                <div className="col-md-6">
                  {" "}
                  <ShimmerEffect height={"200px"} width={"100%"} />
                </div>
              </div>
            </div>
            {[...Array(6)].map((element, index) => {
              return (
                <div key={index + 1} className="col-md-4 my-3">
                  <ShimmerEffect height={"250px"} width={"100%"} />
                  <ShimmerEffect height={"40px"} width={"100%"} />
                  <ShimmerEffect height={"60px"} width={"100%"} />
                  <ShimmerEffect height={"10px"} width={"100%"} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12 no-blog-wrapper text-center">
              <p>No blog available</p>
              <img
                src="/images/no_data.png"
                alt="no-data"
                className="img-fluid h-25"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BlogPage;
