import React from "react";


import Sidebar from "./Sidebar";
import Footer from './Footer';
import Navbar from './Navbar';
import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";

function MasterLayout() {

  const dispatch = useDispatch()
  const sidebar = useSelector(state => state.sidebar.sidebar_status)

  //CLOSE SIDEBAR ON OUTSIDE CLICK IN RESPONSIVE VIEW
  const onCloseSide = () =>{
    
    
    if(window.innerWidth<=1200){
        dispatch({type:"SET_SIDEBAR_STATUS" , payload : "close"})
    }
}


  return (
    <div className="master-layout">
     <div className={`layout-col-1 ${sidebar === "close" ? "side-close":"side-open"}`}>
        <Sidebar/>
        </div>
      <div className={`layout-col-2 ${sidebar==="close" ? 'w-100' : ''}`}>
        <Navbar/>
        <main className="content" onClick = {onCloseSide}>
          <Outlet/>
        </main>
        <Footer/>
      </div>
    </div>
  );
}

export default MasterLayout;
