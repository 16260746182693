import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
function ResetPassword() {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const params = useParams();
  // const navigate = useNavigate()


  const onFinish = (values) => {
    if (values.password === values.confirm_password) {
      setLoading(true)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/adminapi/reset-password/${params.id}/${params.date}`,
          { password: values.password }
        )
        .then((response) => {
          if (response?.status >= 200 && response?.status < 300) {
            if (response?.data.status) {
              message.success(`${response?.data?.message}`)
              setLoading(false)
              form.resetFields()
              // navigate('/login')
            }
          } else {
            message.error(response?.message)
          }

        }).catch((error) => {
          // Server can't be reached!
          if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message)
          } else {
            message.error(error?.message)
          }
        });
    } else {
      message.error("password doesn't match")
      setLoading(false)
    }
    setLoading(false)
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 px-0">
          <div className="form-bg">
            <div className="form-card p-4 col-md-3">
              <div className="col-md-12 mt-4">

                <Form
                  name="normal_login"
                  className="login-form"
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                >
                  <Form.Item
                    name="password"
                    className="form-item"
                    label="new password"
                    rules={[
                      {
                        pattern:
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        message:
                          "password must contain minimum eight characters, at least one letter, one number and one special character."
                      },
                      {
                        required: true,
                        message: "Please input your password!",
                        whitespace: true
                      }
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    className="form-item"
                    label="Confirm Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                        whitespace: true
                      }
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <div className="col-md-12 text-center">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="form-btn border-0"
                        htmlType="submit"
                        to="/"
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                  </div>
                </Form>


              </div>
              <div className="col-md-12">
                <p className="text-center fs-6"><Link to="/" className="font-black fw-500">Back to login?</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
