import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import instance from "../../../api/api_instance";
import ButtonLoader from "../../ButtonLoader";
const {TextArea} = Input;

function CategoryForm({ isEditForm, categoryData ,change ,getData }) {

  const createSlug = (event) => {
    var convertToLower = event.target.value.toLowerCase();
    var slug = convertToLower.split(/[' '/]+/).join("-");
    form.setFieldsValue({
      category_slug: slug,
    });
  };


  const [form] = Form.useForm();
  const[btnLoading , setBtnLoading] = useState(false)

  const onEdit = (values) => {
    const category_data = {
      category : values?.product_category,
      category_slug : values?.category_slug,
      meta_title : values?.meta_title,
      head_title : values?.head_title,
      meta_desc : values?.meta_desc,
      meta_keyword : values?.meta_keyword
    }
    var config = {
      url: `/product-category/edit/${categoryData._id}`,
      method: "POST",
      data : category_data
    };
  
    instance(config).then((response) => {
      if (response?.status >= 200 && response?.status < 300){
        if (response.data.status) {
          form.resetFields()
          getData()
          change(false)
          message.success({ content: response.data.message, key: "loading" })
          // alert(res.data.message)
        }
        else {
          message.error({ content: response.data.message, key: "loading" })
        }
      }else{
        message.error("Something went wrong! try again later")
      }
    }).catch((error) => {

      // Server can't be reached!
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error(error?.message)
      }
    });

  }


  const onNew = (values)=>{
    setBtnLoading(true)
    const category_data = {
      category : values?.product_category,
      category_slug : values?.category_slug,
      meta_title : values?.meta_title,
      head_title : values?.head_title,
      meta_desc : values?.meta_desc,
      meta_keyword : values?.meta_keyword
    }
    var config = {
      url: `/product-category/create`,
      method: "POST",
      data : category_data
    };
  
    instance(config).then((response) => {
      if (response?.status >= 200 && response?.status < 300){
        if (response.data.status) {
          form.resetFields()
          getData()
          setBtnLoading(false)
          message.success(response.data.message)
          // alert(res.data.message)
        }
        else {
          setBtnLoading(false)
          message.error(response.data.message)
        }
      }else{
        setBtnLoading(false)
        message.error("Something went wrong! try again later")
      }
    }).catch((error) => {
      // Server can't be reached!
      if (error?.response?.data?.message) {
        setBtnLoading(false)
        message.error(error?.response?.data?.message)
      } else {
        setBtnLoading(false)
        message.error(error?.message)
      }
    });
  }

  const onFinish = (values) => {
    if(isEditForm){
      onEdit(values)
    }else{
      onNew(values)
    }
  };

  const handleOnCancel = ()=>{
    change(false)
  }

  useEffect(() => {
    if(isEditForm == true){
        form.setFieldsValue({
            product_category: categoryData?.name,
            category_slug: categoryData?.category_slug,
            meta_title: categoryData?.meta_title,
            meta_desc: categoryData?.meta_desc,
            head_title: categoryData?.head_title,
            meta_keyword : categoryData?.meta_keyword
          });
    }else{
        form.setFieldsValue({
            product_category: "",
            category_slug: "",
          }); 
    }
   
  }, [categoryData, isEditForm]);

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6 secondary-font">{isEditForm ? "Edit" : "Add"} Category</p>
      </div>
      <div className="card-body">
        <Form
          name="product_form"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                name="product_category"
                label="Product category"
                rules={[
                  {
                    required: true,
                    message: "Please input product category!",
                  },
                ]}
              >
                <Input onChange={createSlug} placeholder="Enter Product Category" />
              </Form.Item>
              </div>
              <div className="col-md-12">
              <Form.Item
                name="category_slug"
                label="Slug"
                rules={[
                  {
                    required: true,
                    pattern: /[a-zA-Z0-9\-\_]$/,
                    message:
                      "Only characters ,number and underscore is allowed in slug",
                    // message: "Please input product slug!",
                  },
                ]}
              >
                <Input placeholder="Enter Product slug" />
              </Form.Item>
              </div>
              <div className="col-md-12">
              <Form.Item
                name="meta_title"
                label="Meta Title"
                rules={[
                  {
                    required: true,
                    message: "Please input meta title!",
                  },
                ]}
              >
                <Input placeholder="Enter Meta Title" />
              </Form.Item>
              </div>
              <div className="col-md-12">
              <Form.Item
                name="meta_keyword"
                label="Meta Keyword"
                rules={[
                  {
                    required: true,
                    message: "Please input meta keyword!",
                  },
                ]}
              >
                <Input placeholder="Enter meta keyword" />
              </Form.Item>
              </div>
              <div className="col-md-12">
              <Form.Item
                name="meta_desc"
                label="Meta Description"
                rules={[
                  {
                    required: true,
                    message: "Please input meta description!",
                  },
                ]}
              >
                <TextArea rows={4}  placeholder="Enter Meta Description" />
              </Form.Item>
              </div>
              <div className="col-md-12">
              <Form.Item
                name="head_title"
                label="Head Title"
                rules={[
                  {
                    required: true,
                    message: "Please input head title!",
                  },
                ]}
              >
                <Input placeholder="Enter Head Title" />
              </Form.Item>
              </div>
              
            <div className="col-md-12 mt-2">
              <Form.Item className="text-end">
                <Button htmlType="submit" className="form-btn-style" disabled={btnLoading ? true : false}>
                {btnLoading ? (
                  <span className="me-2">
                    <ButtonLoader />
                  </span>
                ) : (
                  ""
                )}
                  {isEditForm ? "Edit" : "Add"}
                </Button>

                <Button htmlType="button" onClick = {handleOnCancel} className={`form-btn-style ${isEditForm ? '':'d-none'}`} disabled = {isEditForm ? false : true}>
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CategoryForm;
