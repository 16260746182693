import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message, Image } from "antd";
import { BsUpload } from "react-icons/bs";
import ButtonLoader from "../../../component/ButtonLoader";
import instance from "../../../api/api_instance";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useParams } from "react-router-dom";
import ImageSeoModal from "../../../component/ImageSeoModal";

const { TextArea } = Input;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function EditBlog() {
  const [form] = Form.useForm();

  const { id } = useParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const[textEditorData , setTextEditorData] = useState(false)
  const [previousBlogImage, setPreviousBlogImage] = useState();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const modules = {
    toolbar: [
      [{ header: [2, 3, 4, 5, 6, false] }],
      [{ size: [] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };
  const fetchBlogData = () => {
    var config = {
      url: `/blog/${id}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setPreviousBlogImage(response?.data?.data?.image ?? "");
            form.setFieldsValue({
              headline: response?.data?.data?.headline,
              description: response?.data?.data?.description,
              meta_title: response?.data?.data?.meta_title,
              head_title: response?.data?.data?.head_title,
              meta_description: response?.data?.data?.meta_description,
              slug: response?.data?.data?.slug,
              meta_keyword: response?.data?.data?.meta_keyword,
            });
            setTextEditorData(response?.data?.data?.description)
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const onFinish = (values) => {
    setBtnLoading(true);
    let formData = new FormData();
    formData.append("headline", values?.headline);
    formData.append("description", values?.description);
    formData.append("meta_title", values.meta_title);
    formData.append("head_title", values.head_title);
    formData.append("meta_description", values.meta_description);
    formData.append("meta_keyword", values.meta_keyword);
    formData.append("slug", values.slug);
    formData.append("id", id);

    if (values?.blog_image) {
      formData.append(
        "blog_image",
        values?.blog_image?.fileList[0]?.originFileObj
      );
    }

    var config = {
      url: `/blog/edit`,
      method: "POST",
      data: formData,
      headers : {
        "Content-Type" : "multipart/form-data"
      }
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({
              content: response.data.message,
              key: "loading",
            });
            form.resetFields(['blog_image']);
            fetchBlogData()
            setBtnLoading(false);
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error("Something went wrong! try again later");
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        console.log(error)
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
          setBtnLoading(false);
        } else {
          message.error(error?.message);
          setBtnLoading(false);
        }
      });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  useEffect(() => {
    fetchBlogData();
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Edit Blog
                <span className="primary-font ms-2"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <div className="card border-0">
            <div className="card-header border-0">
              <p className="mb-0 fw-500 fs-6 secondary-font">Create Blog</p>
            </div>
            <div className="card-body">
              <Form
                name="product_form"
                className="login-form"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item
                      name="headline"
                      label="Blog Headline"
                      rules={[
                        {
                          required: true,
                          message: "Please input blog headline!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={3}
                        // onChange={createSlug}
                        placeholder="Enter Short Headline"
                      />
                    </Form.Item>
                  </div>
                  {/* <div className="col-md-6">
                    <Form.Item
                      name="description"
                      label="Blog Description"
                      rules={[
                        {
                          required: true,
                          message: "Please input blog headline!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={3}
                        // onChange={createSlug}
                        placeholder="Enter Blog Description"
                      />
                    </Form.Item>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {" "}
                    <Form.Item
                      name="head_title"
                      label="Head Title"
                      rules={[
                        {
                          required: true,
                          message: "Please input head title!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Head Title" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_title"
                      label="Meta Title"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta title!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Meta Title" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_keyword"
                      label="Meta Keyword"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta keyword!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter meta keyword" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="slug"
                      label="Slug"
                      rules={[
                        {
                          required: true,
                          pattern: /[a-zA-Z0-9\-\_]$/,
                          message:
                            "Only characters ,number and underscore is allowed in slug",
                          // message: "Please input product slug!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Product slug" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_description"
                      label="Meta Description"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta description!",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Enter Meta Description" />
                    </Form.Item>
                  </div>
                  <div className="col-auto">
                    <Form.Item
                      name="blog_image"
                      label="Blog Image"
                    >
                      <Upload
                        accept="image/*"
                        listType="picture-card"
                        onPreview={handlePreview}
                        beforeUpload={() => false}
                      >
                        <BsUpload />
                      </Upload>
                    </Form.Item>
                  </div>
                  {previousBlogImage ? (
                    <div className="col-2 border">
                      <Image
                        src={
                          process.env.REACT_APP_API_URL +
                          "/" +
                          previousBlogImage?.path
                        }
                        className="img-fluid"
                        alt="Blog-image"
                      />
                      <ImageSeoModal imageId={previousBlogImage?._id}/>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item
                      name="description"
                      label="Blog description"
                      rules={[
                        {
                          required: true,
                          message: "Please input blog description!",
                        },
                      ]}
                    >
                     <SunEditor
                     setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"150px"
                      }}
                    />
                      {/* <ReactQuill theme="snow" modules={modules} /> */}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <Form.Item className="text-end">
                      <Button
                        htmlType="submit"
                        className="form-btn-style"
                        disabled={btnLoading ? true : false}
                      >
                        {btnLoading ? (
                          <span className="me-2">
                            <ButtonLoader />
                          </span>
                        ) : (
                          ""
                        )}
                        Add
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBlog;
