import React, { useState } from 'react';
import { VscLock } from "react-icons/vsc";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { Link } from 'react-router-dom';

function ForgotPassword() {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/adminapi/forgot-password`, {
        email: values.email
      })
      .then((response) => {
        if (response.statusText == "OK" && response.status >= 200 && response.status < 300) {
          if (response.data.status) {
            message.success("Link has been sent to registered mail")
            setLoading(false)
            form.resetFields()
          } else {
            message.error(`${response.data.message}`);
            setLoading(false)
          }
        } else {
          message.error(response.message)
        }

      }).catch((error) => {
        // Server can't be reached!
        if (error.response.data.message) {
          message.error(error.response.data.message)
        } else {
          message.error(error.message)
        }
      });
  };

  return (
    <div className="container py-5">
      <div className="row ">
        <div className="col-md-5 mx-auto form-card">
          <div className="forgot-password-card-header">
            <div className='row'>
              <div className="col-md-12 text-center">
                <img src="images/logo.png" alt="Logo" />
              </div>
            </div>

            <div className="lock-icon d-flex align-items-center justify-content-center mt-3">
              <VscLock />
              <p className='mb-0 ms-2 fw-500 text-center'>Trouble Logging In</p>
            </div>

            <p className='mt-3'>
              Enter your email and we'll send you a link to get back into
              your account.
            </p>
          </div>
          <div className="row form-body mt-5">
            <div className='col-md-12'>
              <Form
                name="normal_login"
                className="login-form"
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                      whitespace: true
                    }
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <div className='row align-items-end'>
                  <div className='col-sm-12 text-center'>
                    <Form.Item>
                      <button
                        className="form-btn"
                        htmlType="submit"
                        to="/"
                      >
                        Send login link
                      </button>
                    </Form.Item>
                  </div>
                    <div className="col-sm-12 text-center">
                      <p className="fs-6"><Link to="/" className="primary-font font-black fw-500">Forgot it ? Back to login</Link></p>
                    </div>
                </div>

              </Form>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;