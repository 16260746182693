import { Form, Input, Button, Upload, Image, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import instance from "../../../api/api_instance";
import { useEffect } from "react";
import { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import ButtonLoader from "../../ButtonLoader";
import ImageSeoModal from "../../ImageSeoModal";

function AboutProducts() {
  const [form] = Form.useForm();

  const [prevProductImg, setPrevProductImg] = useState(null);
  const[btnLoading , setBtnLoading] = useState(false)
  const [textEditorData , setTextEditorData] = useState("")

  // const modules = {
  //   toolbar: [
  //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  //     [{ size:[] }],
  //     [{ 'color': [] }, { 'background': [] }], 
  //     ['bold', 'italic', 'underline','strike', 'blockquote'],
  //     [{'list': 'ordered'}, {'list': 'bullet'}, ],
  //     ['link'],
  //     ['clean']
  //   ],
  // }

  const onFinish = (values) => {
    setBtnLoading(false)
    let formData = new FormData();
    formData.append("count", values?.count);
    formData.append("heading", values?.heading);
    formData.append("description", values?.description);
    if (values?.product_image) {
      formData.append(
        "product_image",
        values?.product_image?.fileList[0]?.originFileObj
      );
    }
    var config = {
      url: `/about/product/insert`,
      method: "POST",
      data: formData,
      headers : {
        "Content-Type" : "multipart/form-data"
      }
    };
    instance(config)
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300
        ) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            get_data()
            form.resetFields(["product_image"]);
            setBtnLoading(false)
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false)
          }
        } else {
          message.error({key:"loading",content:"Something went wrong! try again later"});
          setBtnLoading(false)
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({key:"loading",content : error?.response?.data?.message});
          setBtnLoading(false);
        } else {
          message.error({key:"loading",content:error?.message});
          setBtnLoading(false);
        }
      });
  };

  const get_data = () => {
    message.loading({key:"loading",content:"Please wait..."})
    var config = {
      url: `/about/product`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            form.setFieldsValue({
              count: response?.data?.data?.count,
              heading: response?.data?.data?.heading,
              description: response?.data?.data?.description,
            });
            setTextEditorData(response?.data?.data?.description)
            setPrevProductImg(response?.data?.data?.image);
            form.resetFields(["product_image"]);
            message.success({key:"loading",content:"Data Loaded."})
          } else {
            message.error({key:"loading",content:response.data.message})
            // message.error(response.data.message);
          }
        } else {
          message.error({key:"loading",content:response.message})
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({key:"loading",content:error.response.data.message});
        } else {
          message.error({key:"loading",content:error.message});
        }
      });
  };

  useEffect(() => {
    get_data();
  }, []);

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">About Product</p>
      </div>
      <div className="card-body">
        <Form
          name="product_form"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                name="count"
                label="Number of products"
                rules={[
                  {
                    required: true,
                    message: "Please input number of products!",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter Number of products" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="heading"
                label="Heading"
                rules={[
                  {
                    required: true,
                    message: "Please input heading!",
                  },
                ]}
              >
                <Input placeholder="Enter Heading" />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please input description!",
                  },
                ]}
              >
              <SunEditor
                      setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"200px"
                      }}
                    />
                {/* <ReactQuill theme="snow"  modules={modules} /> */}
                {/* <TextArea rows="4" placeholder="Enter Description" /> */}
              </Form.Item>
            </div>
            <div className="col-auto">
              <Form.Item
                name="product_image"
                label="Image"
                rules={[
                  {
                    required:prevProductImg?false: true,
                    message: "Please upload image!",
                  },
                ]}
              >
              <Upload
                  listType="picture-card"
                  accept="image/*"
                  multiple={false}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}></Button>
                </Upload>
              </Form.Item>
            </div>

            {prevProductImg && prevProductImg?._id? (
              <div className="col-md-3">
                <Image
                  src={`${
                    process.env.REACT_APP_API_URL + "/" + prevProductImg?.path
                  }`}
                  alt="product-img"
                  className="img-fluid form-banner-img"
                />
                <ImageSeoModal imageId={prevProductImg?._id}/>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-md-12 mt-2">
            <Form.Item className="text-end">
            <Button
                htmlType="submit"
                className="form-btn-style"
                disabled={btnLoading ? true : false}
              >
                {btnLoading ? (
                  <span className="me-2">
                    <ButtonLoader />
                  </span>
                ) : (
                  ""
                )}
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AboutProducts;
