import React from 'react'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <div class="error-page-wrapper"><h1 className='text-center'>404 Error</h1>
    {/* <p class="zoom-area"><b>CSS</b> animations to make a cool 404 page. </p> */}
    <section class="error-container">
      <span>4</span>
      <span><span class="screen-reader-text">0</span></span>
      <span>4</span>
    </section>
    <div class="link-container">
      <Link to ="/" class="more-link fw-bold">Back to home page</Link>
    </div></div>
  )
}
