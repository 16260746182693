import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  Image,
  Table,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import instance from "../../../api/api_instance";
import ButtonLoader from "../../ButtonLoader";
import ImageSeoModal from "../../ImageSeoModal";

export default function FeatureVideo() {
  const [form] = Form.useForm();
  const [edit_form] = Form.useForm();
  const [videoData, setVideosData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalImg, setEditModalImg] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const showModal = (record) => {
    edit_form.setFieldsValue({
      id: record?._id,
      link: record?.link,
    });
    setEditModalImg(record?.image ?? "");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditModalImg("");
  };
  const onFinish = (values) => {
    let formData = new FormData();
    formData.append("link", values?.link);
    if (values?.video_img) {
      formData.append(
        "video_img",
        values?.video_img?.fileList[0]?.originFileObj
      );
    }
    var config = {
      url: `/featured_video/insert`,
      method: "POST",
      data: formData,
      headers : {
        "Content-Type" : "multipart/form-data"
      }
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            form.resetFields();
            get_all_videos();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const onEditService = (values) => {
    let formData = new FormData();
    formData.append("link", values?.link);
    if (values?.video_img) {
      formData.append(
        "video_img",
        values?.video_img?.fileList[0]?.originFileObj
      );
    }
    var config = {
      url: `/featured_video/edit/${values.id}`,
      method: "POST",
      data: formData,
      headers : {
        "Content-Type" : "multipart/form-data"
      }
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            setIsModalOpen(false);
            edit_form.resetFields(["video_img"]);
            setEditModalImg("");
            get_all_videos();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const get_all_videos = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/featured_video`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setVideosData(response.data.data);
            message.success({ key: "loading", content: "Data Loaded." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  const deleteVideo = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/featured_video/delete/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              get_all_videos();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const columns = [
    {
      title: "_id",
      key: "_id",
      dataIndex:"_id",
      // render: (text, object, index) => {
      //   return index + 1;
      // },
      hidden:true
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <a
          href={`${text}`}
          target="_blank"
          className="fw-500 text-decoration-underline"
        >
          Click here to visit link
        </a>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <div key={record._id} className="">
          {record?.image?._id ? (
            <div>
            <Image
              src={`${process.env.REACT_APP_API_URL + "/" + record?.image?.path}`}
              alt=""
              className="img-fluid table-image"
            />
            <ImageSeoModal imageId={record?.image?._id}/>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <div key={record._id} className="d-md-flex">
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => showModal(record)}
          >
            <FiEdit />
          </Button>
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteVideo(record._id)}
          >
            <AiOutlineDelete
              className="font-red "
              style={{ cursor: "pointer" }}
            />
          </Button>
        </div>
      ),
    },
  ].filter(item => !item.hidden);;

  useEffect(() => {
    get_all_videos();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card border-0">
          <div className="card-header border-0">
            <p className="mb-0 fw-500 fs-6">Add Featured Video</p>
          </div>
          <div className="card-body">
            <Form
              name="featureVideoForm"
              className="login-form"
              initialValues={{ remember: true }}
              form={form}
              layout={"vertical"}
              onFinish={onFinish}
              requiredMark={false}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    label="Link"
                    name="link"
                    rules={[
                      {
                        required: true,
                        type: "url",
                        message: "Please input link!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Link" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="video img"
                    name="video_img"
                    rules={[
                      {
                        required: true,
                        message: "Please upload Video Image!",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/*"
                      multiple={false}
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}>
                        Click to Upload Image
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
              <Form.Item className="text-end">
                <Button htmlType="submit" className="form-btn-style">
                  Add
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="col-md-12 my-3">
        <div className="card border-0">
          <div className="card-body table-responsive p-0">
            <Table dataSource={videoData} columns={columns} rowKey="_id" />
          </div>
        </div>
      </div>
      <Modal
        title="Edit service"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="service_edit_form"
          className="login-form"
          initialValues={{ remember: true }}
          form={edit_form}
          layout={"vertical"}
          onFinish={onEditService}
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                name="id"
                rules={[{ required: true, message: "Please input your id!" }]}
                className="d-none"
              >
                <Input type="hidden" placeholder="Id" className="d-none" />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                label="Link"
                name="link"
                rules={[
                  {
                    required: true,
                    type: "url",
                    message: "Please input link!",
                  },
                ]}
              >
                <Input placeholder="Enter Link" />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item label="video img" name="video_img">
                <Upload
                  accept="image/*"
                  multiple={false}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Image
                  </Button>
                </Upload>
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Image
                src={`${process.env.REACT_APP_API_URL + "/" + editModalImg?.path}`}
                alt=""
                className="img-fluid "
              />
            </div>

            <div></div>
          </div>
          <Form.Item className="text-end">
            <Button
              htmlType="submit"
              className="form-btn-style"
              disabled={btnLoading ? true : false}
            >
              {btnLoading ? (
                <span className="me-2">
                  <ButtonLoader />
                </span>
              ) : (
                ""
              )}
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
