import { message, Table, Button, Modal } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import instance from "../../api/api_instance";
import EnquiryHeadings from "../../component/admin_component/contact_us/EnquiryHeadings";

export default function EnquiryManage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewModalData, setViewModalData] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);


  const showModal = (record) => {
    setIsModalOpen(true);
    setViewModalData(record);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteEnquiry = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/enquiry/delete/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (
            
            response.status >= 200 &&
            response.status < 300
          ) {
            if (response.data.status === true) {
              fetchData();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  // FETCH SEO TABLE DATA

  
  const fetchData = () => {
    message.loading({key:"loading",content:"Loading..."})
    var config = {
      url: `/get-enquiries`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            setEnquiryData(response?.data?.data ?? []);
            message.success({key:"loading",content:""})
          } else {
            message.error({key:"loading",content:response.data.message});
          }
        } else {
          message.error({key:"loading",content:response.message});
        }
      })
      .catch((error) => {
        message.error({key:"loading",content:error.message});
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    // {
    //   title: "S no",
    //   key: "sno",

    //   render: (text, object, index) => {
    //     return index + 1;
    //   },
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Enquiry For",
      dataIndex: "enquiry_for",
      key: "enquiry_for",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "200",
      render: (text, record) => (
        <div key={record._id} className="d-flex">
          <Button
            className="btn btn-outline-info btn-sm m-1 fw-500"
            onClick={() => showModal(record)}
            // onClick={}
          >
            <AiOutlineEye />
          </Button>

          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteEnquiry(record._id)}
          >
            <AiOutlineDelete className="font-red" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Manage Enquiry
                <span className="primary-font ms-2"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
          <div className="col-md-12">
            <EnquiryHeadings/>
          </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <div className="card border-0 p-0 table-responsive">
            <Table dataSource={enquiryData} columns={columns} />
          </div>
        </div>
      </div>
      <Modal
        title="View Enquiry"
        visible={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        width={800}
      >
        <div className="row ">
          <div className="col-md-4">
            <p className="fw-500">Name :</p>
            <p>{viewModalData?.name ?? ""}</p>
          </div>
          <div className="col-md-4">
            <p className="fw-500">Email :</p>
            <p>{viewModalData?.email ?? ""}</p>
          </div>
          <div className="col-md-4">
            <p className="fw-500">Mobile :</p>
            <p>{viewModalData?.mobile ?? ""}</p>
          </div>
        </div>
        <div className="row mt-3">
          {viewModalData?.enquiry_for ? (
            <div className="col-md-4">
              <p className="fw-500">Enquiry For :</p>
              <p>{viewModalData?.enquiry_for ?? ""}</p>
            </div>
          ) : (
            ""
          )}
          {viewModalData?.comment ? (
            <div className="col-md-8 ">
              <p className="fw-500">Comment :</p>
              <p>{viewModalData?.comment ?? ""}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </div>
  );
}
