import React, { useEffect, useState } from "react";
import { AiTwotoneCalendar, AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { message } from "antd";
import instance from "../../api/web_instance";
import moment from "moment";
import MoreBlog from "./MoreBlog";
import { Helmet } from "react-helmet";
import ShimmerEffect from "../../component/ShimmerEffect";

function BlogDetailsPage() {
  const { slug } = useParams();
  const [blogDetails, setBlogDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const get_data = () => {
    setIsLoading(true);
    var config = {
      url: `/blog/${slug}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setBlogDetails(response.data.data);
            setIsLoading(false);
          } else {
            message.error({ key: "loading", content: response.data.message });
            setIsLoading(false);
          }
        } else {
          message.error({ key: "loading", content: response.message });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    get_data();
    window.scrollTo(0, 0);
  }, [slug]);

  return (
    <div className="container py-4">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {blogDetails?.head_title ? blogDetails.head_title : "Oliver Rubber"}
        </title>
        <meta
          name="title"
          content={
            blogDetails?.meta_title ? blogDetails.meta_title : "Oliver Rubber"
          }
        ></meta>
        <meta
          name="description"
          content={
            blogDetails?.meta_description
              ? blogDetails.meta_description
              : "Oliver Rubber"
          }
        ></meta>
        <meta
          name="keyword"
          content={blogDetails?.meta_keyword ? blogDetails.meta_keyword : ""}
        ></meta>
        <link rel="canonical" href={`https://oliverllp.com/blog/${slug}`} />
      </Helmet>

      {isLoading ? (
        <div className="row">
          <div className="col-md-8">
            <ShimmerEffect height={"50px"} width={"90%"} />
            <ShimmerEffect height={"20px"} width={"150px"} />
            <ShimmerEffect height={"500px"} width={"100%"} />
            <div className="row">
              {[...Array(3)].map((element, index) => {
                return (
                  <div key={index + 1} className="col-md-12">
                    <ShimmerEffect height={"150px"} width={"100%"} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <p className="font-24 fw-500 dark-font">More Blog</p>

              {[...Array(5)].map((element, index) => {
                return (
                  <div key={index} className="col-md-12">
                    <ShimmerEffect height={"100px"} width={"100%"} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-8">
            <h1 className="font-30 dark-font fw-500">
              {blogDetails?.headline ?? ""}
            </h1>
            <p className="fw-500 font-18">
              <AiTwotoneCalendar className="fs-4 text-success me-2" />{" "}
              {blogDetails?.createdAt
                ? moment(blogDetails?.createdAt).format("d MMM YYYY")
                : ""}
            </p>
            <img
              src={`${process.env.REACT_APP_API_URL}/${blogDetails?.image?.path}`}
              alt={`${blogDetails?.image?.alternate_text ?? "blog-image"}`}
              title={blogDetails?.image?.title}
              caption={blogDetails?.image?.caption}
              description={blogDetails?.image?.description}
              className="img-fluid blog-xxl-img"
            />
            <ul className="blog-social-media-links">
              <li>
                <span>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${process.env.REACT_APP_WEBSITE_URL}/blogdetails/${slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsFacebook />
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_WEBSITE_URL}/blogdetails/${slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <a
                    className="color-primary "
                    href={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_WEBSITE_URL}/blogdetails/${slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiFillTwitterCircle />
                  </a>
                </span>
              </li>

              <li>
                <span>
                  <a
                    className="color-primary "
                    href={`https://www.instagram.com/?url=${process.env.REACT_APP_WEBSITE_URL}/blogdetails/${slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsInstagram />
                  </a>
                </span>
              </li>
            </ul>
            {blogDetails.description ? (
              <div
                className="text-justify font-20 table-responsive sunEditor-react"
                dangerouslySetInnerHTML={{ __html: blogDetails.description }}
              ></div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-4">
            <div className="row">
              <p className="font-24 fw-500 dark-font">More Blogs</p>
            </div>
            <MoreBlog />
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogDetailsPage;
