import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import instance from "../../../api/api_instance";
import { useEffect } from "react";


export default function ProductDetails() {
  const { id } = useParams();
  const [productData, setProductData] = useState([]);
  
  const fetchProductData = () => {
    var config = {
      url: `/product/${id}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            setProductData(response?.data?.data ?? {});
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  
  useEffect(() => {
   
    fetchProductData();
  }, [id]);

  useEffect(()=>{
    document.title = "Product"
  })

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Product Details ({productData?.category?.name ?? ""})
                {/* <span className="primary-font ms-2">(On website)</span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 my-3">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <p className="fw-500 fs-4 text-center">
                    {productData?.name ?? ""}
                  </p>
                  <p className="text-center">
                    {productData?.introduction ?? ""}
                  </p>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <div
                  id="carouselExampleControls"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                  {productData.images && productData.images.length > 0
                      ? productData.images.map((element, index) => {
                          return <div key={index+1} class={`carousel-item ${index + 1 == 1 ? 'active':""}`}>
                            <img
                              class="img-400"
                              src={`${
                                process.env.REACT_APP_API_URL + "/" + element?.path
                              }`}
                              alt="First slide"
                            />
                          </div>;
                        })
                      : ""}
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div
                className="col-md-12"
                dangerouslySetInnerHTML={{
                  __html: productData?.description ?? "",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
