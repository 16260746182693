import React, { useState } from "react";
import { Form, Button, Upload, message } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import instance from "../../../api/api_instance";
import { useEffect } from "react";
import ButtonLoader from "../../ButtonLoader";

function Broucher() {
  const [form] = Form.useForm();
  const [prevBroucher, setPrevBroucher] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const get_broucher = () => {
    message.loading({key:"loading",content:"Please wait..."})
    var config = {
      url: `/broucher`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setPrevBroucher(response?.data?.data);
            message.success({key:"loading",content:"Data Loaded"})
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const onFinish = (values) => {
    let formData = new FormData();
    formData.append("broucher", values?.broucher?.fileList[0]?.originFileObj);
    var config = {
      url: `/broucher/upload`,
      method: "POST",
      headers : {
        "Content-Type" : "multipart/form-data"
      },
      data: formData,
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            form.resetFields();
            get_broucher();
            setBtnLoading(false);
            // alert(res.data.message)
          } else {
            setBtnLoading(false);
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          setBtnLoading(false);
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          setBtnLoading(false);
          message.error(error?.response?.data?.message);
        } else {
          setBtnLoading(false);
          message.error(error?.message);
        }
      });
  };

  useEffect(() => {
    get_broucher();
  }, []);

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">Upload Broucher</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-auto">
            <Form
              name="aboutus_form"
              //   className="login-form"
              initialValues={{ remember: true }}
              form={form}
              layout={"vertical"}
              onFinish={onFinish}
              requiredMark={false}
            >
              <Form.Item
                // label="Image"
                name="broucher"
                rules={[
                  {
                    required: true,
                    message: "Please upload brochure!",
                  },
                ]}
              >
                <Upload
                  multiple={false}
                  accept=".doc,.docx,application/pdf"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to Upload brochure
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item className="text-start">
                <Button
                  htmlType="submit"
                  className="form-btn-style"
                  disabled={btnLoading ? true : false}
                >
                  {btnLoading ? (
                    <span className="me-2">
                      <ButtonLoader />
                    </span>
                  ) : (
                    ""
                  )}
                  Upload
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="col-auto">
            <a
              href={`${process.env.REACT_APP_API_URL}/${prevBroucher?.broucher}`}
              target="_blank"
              className="fw-500 text-decoration-underline"
            >
              Click here to view brochure
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Broucher;
