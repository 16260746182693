import React, { useState, useEffect } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  Table,
  Image,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import instance from "../../../api/api_instance";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import ImageSeoModal from "../../ImageSeoModal";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export default function Services() {
  const [form] = Form.useForm();
  const [edit_form] = Form.useForm();
  const [serviceData, setServicesData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalImg, setEditModalImg] = useState("");
  const [textEditorData , setTextEditorData] = useState("")

  const showModal = (record) => {
    edit_form.setFieldsValue({
      id: record?._id,
      heading: record?.heading,
      description: record?.description,
    });
    setTextEditorData(record?.description)
    setEditModalImg(record?.icon ?? "");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditModalImg("");
  };
  // INSERT SERVICES
  const onFinish = (values) => {
    message.loading({ key: "submit", content: "Please wait..." });
    let formData = new FormData();
    formData.append("heading", values?.heading);
    formData.append("description", values?.description);
    if (values?.service_icon) {
      formData.append(
        "service_icon",
        values?.service_icon?.fileList[0]?.originFileObj
      );
    }
    var config = {
      url: `/insert-service`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/formdata",
      },
      data: formData,
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "submit" });
            form.resetFields();
            get_all_services();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "submit" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  // EDIT SERVICE
  const onEditService = (values) => {
    let formData = new FormData();
    formData.append("heading", values?.heading);
    formData.append("description", values?.description);
    if (values?.service_icon) {
      formData.append(
        "service_icon",
        values?.service_icon?.fileList[0]?.originFileObj
      );
    }

    var config = {
      url: `/edit-service/${values.id}`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/formdata",
      },
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            setIsModalOpen(false);
            edit_form.resetFields(["service_icon"]);
            setEditModalImg("");
            get_all_services();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const get_all_services = () => {
    message.success({ key: "loading", content: "Loading..." });
    var config = {
      url: `/services`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setServicesData(response.data.data);
            message.success({ key: "loading", content: "Data Loaded" });
          } else {
            message.error({ key: "loading", content: response.data.message });
            // message.error(response.data.message);
          }
        } else {
          message.error({ key: "loading", content: response.message });
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  useEffect(() => {
    get_all_services();
  }, []);

  const deleteService = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/delete-service/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              get_all_services();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const columns = [
    {
      title: "_id",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => {
        return record?._id;
      },
      hidden: true,
    },
    {
      title: "Heading",
      dataIndex: "heading",
      key: "heading",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <p key={record?._id} dangerouslySetInnerHTML={{ __html: text }}></p>
      ),
    },
    {
      title: "Service Icon",
      dataIndex: "icon",
      key: "icon",
      render: (text, record, index) => (
        <div key={record?._id} className="">
          {record?.icon?._id ? (
            <div>
              <Image
                src={`${
                  process.env.REACT_APP_API_URL + "/" + record?.icon?.path
                }`}
                alt="icon_image"
                className="img-fluid table-image"
              />
              <ImageSeoModal imageId={record?.icon?._id} />
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record, index) => (
        <div key={record?._id} className="d-md-flex">
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => showModal(record)}
          >
            <FiEdit />
          </Button>
          <Button
            className="btn btn-outline-primary m-1 btn-sm fw-500"
            onClick={() => deleteService(record._id)}
          >
            <AiOutlineDelete
              className="font-red "
              style={{ cursor: "pointer" }}
            />
          </Button>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card border-0">
          <div className="card-header border-0">
            <p className="mb-0 fw-500 fs-6">Services</p>
          </div>
          <div className="card-body">
            <Form
              name="aboutus_form"
              className="login-form"
              initialValues={{ remember: true }}
              form={form}
              layout={"vertical"}
              onFinish={onFinish}
              requiredMark={false}
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Item
                    label="Heading"
                    name="heading"
                    rules={[
                      { required: true, message: "Please input your heading!" },
                    ]}
                  >
                    <Input placeholder="Enter Heading" />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please input your description!",
                      },
                    ]}
                  >
                     <SunEditor
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"200px"
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    label="Service Icon"
                    name="service_icon"
                    rules={[
                      {
                        required: true,
                        message: "Please upload service icon!",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/*"
                      multiple={false}
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}>
                        Click to Upload Logo
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
              <Form.Item className="text-end">
                <Button htmlType="submit" className="form-btn-style">
                  Add
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="col-md-12 my-3">
        <div className="card  border-0">
          <div className="card-body p-0 table-responsive">
            <Table dataSource={serviceData} columns={columns} rowKey="_id" />
          </div>
        </div>
      </div>
      <Modal
        title="Edit service"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="service_edit_form"
          className="login-form"
          initialValues={{ remember: true }}
          form={edit_form}
          layout={"vertical"}
          onFinish={onEditService}
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                name="id"
                rules={[{ required: true, message: "Please input your id!" }]}
                className="d-none"
              >
                <Input type="hidden" placeholder="Id" className="d-none" />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                label="Heading"
                name="heading"
                rules={[
                  { required: true, message: "Please input your heading!" },
                ]}
              >
                <Input placeholder="Enter Heading" />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input your description!",
                  },
                ]}
              >
                <SunEditor
                      setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"200px"
                      }}
                    />
                {/* <ReactQuill theme="snow" modules={modules} /> */}
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item label="Service Icon" name="service_icon">
                <Upload
                  accept="image/*"
                  multiple={false}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Logo
                  </Button>
                </Upload>
              </Form.Item>
            </div>
            {editModalImg?.path ? (
              <div className="col-3">
                <Image
                  src={`${
                    process.env.REACT_APP_API_URL + "/" + editModalImg?.path
                  }`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            ) : (
              ""
            )}
            <div></div>
          </div>
          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
