import React, { useEffect, useState } from "react";
import AddSeoForm from "../../../component/admin_component/seo_component/AddSeoForm";
import { Form, Input, Table, Button, message, Modal, Select } from "antd";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

import instance from "../../../api/api_instance";
const { TextArea } = Input;

export default function ManageSeo() {
  const [edit_form] = Form.useForm();

  const [seoData, setSeoData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [viewModalData, setViewModalData] = useState({});

  const showModal = (record) => {
    setIsModalOpen(true);
    setViewModalData(record);
  };

  const showEditModal = (record) => {
    setIsEditModalOpen(true);
    edit_form.setFieldsValue({
      seo_id: record?._id,
      head_title: record?.head_title,
      meta_title: record?.meta_title,
      page_name: record?.page_name,
      meta_description: record?.meta_description,
      meta_keyword: record?.meta_keyword,
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
  };

  const deleteSeo = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/seo/delete/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              fetchData();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  // FETCH SEO TABLE DATA

  const fetchData = () => {
    message.loading({ key: "loading", content: "Loading..." });
    var config = {
      url: `/seo`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setSeoData(response?.data?.data ?? []);
            message.success({ key: "loading", content: "" });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
      });
  };

  // ON EDIT SEO DATA
  const onEditSeo = (values) => {
    const seo_data = {
      head_title: values.head_title,
      meta_title: values.meta_title,
      meta_description: values.meta_description,
      page_name: values.page_name,
      meta_keyword: values.meta_keyword,
    };
    var config = {
      url: `/seo/edit/${values.seo_id}`,
      method: "POST",
      data: seo_data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({
              content: response.data.message,
              key: "loading",
            });
            handleCancel();
            fetchData();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    // {
    //   title: "S no",
    //   key: "sno",

    //   render: (text, object, index) => {
    //     return index + 1;
    //   },
    // },
    {
      title: "Head Title",
      dataIndex: "head_title",
      key: "head_title",
    },
    {
      title: "Meta Title",
      dataIndex: "meta_title",
      key: "meta_title",
    },
    {
      title: "Page",
      dataIndex: "page_name",
      key: "page_name",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "200",
      render: (text, record) => (
        <div key={record._id} className="d-flex">
          <Button
            className="btn btn-outline-info btn-sm m-1 fw-500"
            onClick={() => showModal(record)}
            // onClick={}
          >
            <AiOutlineEye />
          </Button>
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => showEditModal(record)}
          >
            <FiEdit className="text-primary" />
          </Button>

          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteSeo(record._id)}
          >
            <AiOutlineDelete className="font-red" />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Manage SEO
                <span className="primary-font ms-2"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <AddSeoForm getData={fetchData} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card border-0 p-0 table-responsive">
            <Table dataSource={seoData} columns={columns} />
          </div>
        </div>
      </div>
      {/* VIEW SEO MODAL */}
      <Modal
        title="View Seo"
        visible={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        width={800}
      >
        <div className="row border-bottom">
          <div className="col-md-4">
            <p className="fw-500">Head Title :</p>
            <p>{viewModalData?.head_title ?? ""}</p>
          </div>
          <div className="col-md-4">
            <p className="fw-500">Meta Title :</p>
            <p>{viewModalData?.meta_title ?? ""}</p>
          </div>
          <div className="col-md-4">
            <p className="fw-500">Page :</p>
            <p>{viewModalData?.page_name ?? ""}</p>
          </div>
          <div className="col-md-4">
            <p className="fw-500">Meta keywords :</p>
            <p>{viewModalData?.meta_keyword ?? ""}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 py-3">
            <p className="fw-500">Meta Description :</p>
            <p>{viewModalData?.meta_description ?? ""}</p>
          </div>
        </div>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        title="Edit Seo"
        visible={isEditModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Close"
        footer={null}
        width={800}
      >
        <Form
          name="product_form"
          className="login-form"
          form={edit_form}
          initialValues={{ remember: true }}
          onFinish={onEditSeo}
          layout="vertical"
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-6 d-none">
              <Form.Item
                name="seo_id"
                rules={[
                  {
                    required: true,
                    message: "Please input seo id!",
                  },
                ]}
                className="d-none"
              >
                <Input disabled={true} placeholder="" className="d-none" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="head_title"
                label="Head Title"
                rules={[
                  {
                    required: true,
                    message: "Please input head title!",
                  },
                ]}
              >
                <Input placeholder="Enter Head Title" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="meta_title"
                label="Meta Title"
                rules={[
                  {
                    required: true,
                    message: "Please input meta title!",
                  },
                ]}
              >
                <Input placeholder="Enter Meta Title" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="meta_description"
                label="Meta Description"
                rules={[
                  {
                    required: true,
                    message: "Please input meta description!",
                  },
                ]}
              >
                <TextArea rows={5} placeholder="Enter Meta Description" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="meta_keyword"
                label="Meta Keyword"
                rules={[
                  {
                    required: true,
                    message: "Please input meta keyword!",
                  },
                ]}
              >
                <Input placeholder="Enter Meta keyword" />
              </Form.Item>
              <Form.Item
                name="page_name"
                label="Page name"
                rules={[
                  {
                    required: true,
                    message: "Please Select page!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Page Name"
                  options={[
                    {
                      label: "Home Page",
                      value: "home",
                    },
                    {
                      label: "About us Page",
                      value: "about",
                    },
                    {
                      label: "Contact us Page",
                      value: "contact",
                    },
                    {
                      label: "Blog Page",
                      value: "blog",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item className="text-end">
                <Button htmlType="submit" className="form-btn-style">
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
