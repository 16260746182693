import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ButtonLoader from "../../../component/ButtonLoader";
import instance from "../../../api/api_instance";
import { useNavigate, useParams } from "react-router-dom";

function EditPage() {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  const [pageDescription, setPageDescription] = useState("");

  const { id } = useParams("id");
  const createSlug = (event) => {
    var convertToLower = event.target.value.toLowerCase();
    var slug = convertToLower.split(/[' '/]+/).join("-");
    form.setFieldsValue({
      page_slug: slug,
    });
  };
  const onFinish = (values) => {
    setBtnLoading(true);
    const page_data = {
      id: id,
      page_title: values?.page_title,
      page_slug: values?.page_slug,
      page_description: values?.page_description,
      meta_title: values?.meta_title,
      meta_keyword: values?.meta_keyword,
      head_title: values?.head_title,
      meta_description: values?.meta_description,
    };
    var config = {
      url: `/page/edit/${id}`,
      method: "POST",
      data: page_data,
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({
              content: response.data.message,
              key: "loading",
            });
            setBtnLoading(false);
            navigate("/admin/pages");
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error("Something went wrong! try again later");
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
          setBtnLoading(false);
        } else {
          message.error(error?.message);
          setBtnLoading(false);
        }
      });
  };

  const fetchData = () => {
    message.loading({ key: "loading", content: "Loading..." });
    var config = {
      url: `page/${id}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            form.setFieldsValue({
              page_title: response.data?.data?.page_title,
              page_slug: response.data?.data?.page_slug,
              meta_title: response?.data?.data?.meta_title,
              meta_keyword: response?.data?.data?.meta_keyword,
              head_title: response?.data?.data?.head_title,
              meta_description: response?.data?.data?.meta_description,
            });
            setPageDescription(response.data?.data?.page_description);
            message.success({ key: "loading", content: "" });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Pages
                <span className="primary-font ms-2">(On website)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <div className="card border-0">
            <div className="card-header border-0">
              <p className="mb-0 fw-500 fs-6 secondary-font">Edit Page</p>
            </div>
            <div className="card-body">
              <Form
                name="product_form"
                className="login-form"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="page_title"
                      label="Page Title"
                      rules={[
                        {
                          required: true,
                          message: "Please input page title!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Page Title" onChange={(e)=>{createSlug(e)}} />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="page_slug"
                      label="Page Slug"
                      rules={[
                        {
                          required: true,
                          message: "Please input page slug!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Page Slug" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_title"
                      label="Meta Title"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta title!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Meta Title" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_keyword"
                      label="Meta Keyword"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta keyword!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Meta Keyword" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="head_title"
                      label="Head Title"
                      rules={[
                        {
                          required: true,
                          message: "Please input head title!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Head Title" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_description"
                      label="Meta Description"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta description!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={3}
                        placeholder="Enter Meta description"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item
                      name="page_description"
                      label="page description"
                      rules={[
                        {
                          required: true,
                          message: "Please input page description!",
                        },
                      ]}
                    >
                      <SunEditor
                        setContents={pageDescription}
                        setOptions={{
                          buttonList: [
                            ["undo", "redo"],
                            ["fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["fontColor", "hiliteColor"],
                            ["align", "horizontalRule", "list", "lineHeight"],
                            ["table", "link", "image"],
                            ["codeView"],
                          ],
                          height: "250px",
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <Form.Item className="text-end">
                    <Button
                      htmlType="submit"
                      className="form-btn-style"
                      disabled={btnLoading ? true : false}
                    >
                      {btnLoading ? (
                        <span className="me-2">
                          <ButtonLoader />
                        </span>
                      ) : (
                        ""
                      )}
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPage;
