import React, { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiEnvelope, BiPhoneCall } from "react-icons/bi";
import { message } from "antd";
import instance from "../../api/web_instance";
import { Helmet } from "react-helmet";
import ShimmerEffect from "../../component/ShimmerEffect";
import EnquiryForm from "./EnquiryForm";

export default function ContactUsPage() {
  const [emails, setEmails] = useState([]);
  const [mobile_no, setMobileNo] = useState([]);
  const [address, setAddress] = useState([]);
  const [visibleMap, setVisibleMap] = useState(0);
  const [seoData, setSeoData] = useState({});
  const [contactHeading, setContactHeading] = useState("");
  const [contactSubHeading, setContactSubHeading] = useState("");
  const [contactImg, setContactImg] = useState("");
  const [enquiryHeading, setEnquiryHeading] = useState("");
  const [enquirySubHeading, setEnquirySubHeading] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const get_data = () => {
    setIsLoading(true);
    var config = {
      url: `/contact-us`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setEmails(response?.data?.data?.email);
            setAddress(response?.data?.data?.address);
            setMobileNo(response?.data?.data?.mobile);
            setSeoData(response?.data?.seo_data);
            setContactHeading(response?.data?.data?.contact_heading);
            setContactSubHeading(response?.data?.data?.contact_sub_heading);
            setContactImg(response?.data?.data?.contact_us_image);
            setEnquiryHeading(response?.data?.data?.enquiry_heading);
            setEnquirySubHeading(response?.data?.data?.enquiry_sub_heading);
            setIsLoading(false);
          } else {
            message.error(response.data.message);
            setIsLoading(false);
          }
        } else {
          message.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_data();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {seoData?.head_title ? seoData.head_title : "Oliver Rubber"}
        </title>
        <meta
          name="title"
          content={seoData?.meta_title ? seoData.meta_title : "Oliver Rubber"}
        ></meta>
        <meta
          name="description"
          content={
            seoData?.meta_description
              ? seoData.meta_description
              : "Oliver Rubber"
          }
        ></meta>
        <meta
          name="keyword"
          content={seoData?.meta_keyword ? seoData.meta_keyword : ""}
        ></meta>
        <link rel="canonical" href="https://oliverllp.com/contact-us" />
      </Helmet>
      {/* <Navbarweb /> */}
      <header className="container-fluid  p-0">
        <div
          className="background-light contact-header-title text-center "
          style={{
            backgroundImage: `url(/images/contactusHeader.png)`,
          }}
        >
          <h1 className="text-white mb-0 heading-font-family">CONTACT US</h1>
        </div>
      </header>

      {/* CONTACT US INFO SECTION */}
      <section className="container-fluid background-light">
        <div className="container py-5">
          <div className="row justify-content-lg-center ">
            <div className="col-lg-10">
              <p className="font-primary fw-500 fs-6 mb-0">Contact Us</p>
            </div>
          </div>
          <div className="row justify-content-lg-center">
            <div className="col-lg-4 section-left-border ps-4">
              {isLoading ? (
                <ShimmerEffect height={"40px"} width={"100%"} />
              ) : (
                <p className="section-heading mb-3">
                  <span>{contactHeading ?? ""}</span>
                </p>
              )}
              {isLoading ? (
                <ShimmerEffect height={"20px"} width={"100%"} />
              ) : (
                <p className="font-18  dark-79">{contactSubHeading ?? ""}</p>
              )}
            </div>

            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              {/* ADDRESS */}
              {isLoading ? (
                <div className="row">
                  {[...Array(2)].map((element, index) => {
                    return (
                      <div key={index + 1} className="col-md-12 mt-4">
                        <ShimmerEffect height={"40px"} width={"80%"} />
                        <ShimmerEffect height={"20px"} width={"80%"} />
                      </div>
                    );
                  })}
                </div>
              ) : address && address?.length > 0 ? (
                <div className="row ">
                  <div className="col-2">
                    <HiOutlineLocationMarker className="fw-bold fs-1" />
                  </div>
                  <div className="col-10">
                    {address.map((address, index) => {
                      return (
                        <div key={index + 1}>
                          <p className="mb-1 fw-500 fw-bold font-18 ">
                            {address?.location ?? ""}
                          </p>
                          <p className="dark-79 fw-500 font-16">
                            {address?.full_address ?? ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* EMAILS */}
              {isLoading ? (
                <div className="row mt-4">
                  {[...Array(2)].map((element, index) => {
                    return (
                      <div key={index + 1} className="col-md-12 mt-2">
                        <ShimmerEffect height={"20px"} width={"80%"} />
                      </div>
                    );
                  })}
                </div>
              ) : emails && emails?.length > 0 ? (
                <div className="row mt-4">
                  <div className="col-2">
                    <BiEnvelope className="fs-1" />
                  </div>
                  <div className="col-10">
                    {emails.map((email, index) => {
                      return (
                        <p
                          key={index + 1}
                          className="mb-0 dark-79 fw-500 font-16"
                        >
                          <a
                            href={`mailto : ${email ?? ""}`}
                            className="dark-79 text-lowercase "
                          >
                            {email ?? ""}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* MOBILE NO. */}
              {isLoading ? (
                <div className="row mt-4">
                  {[...Array(2)].map((element, index) => {
                    return (
                      <div key={index + 1} className="col-md-12 mt-2">
                        <ShimmerEffect height={"20px"} width={"80%"} />
                      </div>
                    );
                  })}
                </div>
              ) : mobile_no && mobile_no?.length > 0 ? (
                <div className="row mt-5">
                  <div className="col-2">
                    <BiPhoneCall className="fs-1" />
                  </div>

                  <div className="col-10">
                    {mobile_no.map((mobile, index) => {
                      return (
                        <p
                          key={index + 1}
                          className="mb-0 dark-79 fw-500 font-16"
                        >
                          <a href={`tel : ${mobile ?? ""}`} className="dark-79">
                            {" "}
                            {mobile ? "+91 " + mobile : ""}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      {/* TALK ABOUT PRODUCT SECTION */}
      <section className="container py-5">
        <div className="row justify-content-lg-center ">
          <div className="col-lg-10">
            <p className="font-primary fw-500 fs-6 mb-0">Enquiry</p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          {
            <div className="col-md-10 section-left-border mb-5 ps-4">
              {isLoading ? (
                <ShimmerEffect height={"40px"} width={"50%"} />
              ) : (
                <p className="section-heading mb-3 ">
                  <span>{enquiryHeading ?? ""}</span>
                </p>
              )}
              {isLoading ? (
                <ShimmerEffect height={"20px"} width={"50%"} />
              ) : (
                <p className="font-18 dark-79">{enquirySubHeading ?? ""}</p>
              )}
            </div>
          }
          <div className="col-lg-5 my-1 ">
            <div className="contact-leftSection-img">
              {isLoading ? (
                <ShimmerEffect height={"20px"} width={"50%"} />
              ) : contactImg?._id ? (
                <img
                  src={`${process.env.REACT_APP_API_URL}/${contactImg?.path}`}
                  alt={contactImg?.alternate_text ?? "contact-img"}
                  title={contactImg?.title ?? "contact-img"}
                  caption={contactImg?.caption ?? "contact-img"}
                  description={contactImg?.description ?? "contact-img"}
                  className="img-fluid"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-lg-5 my-1">
            <div className="background-light contact-form-wrapper">
              <EnquiryForm />
            </div>
          </div>
        </div>
      </section>
      {/* MAP SECTION */}
      <section className="container pb-5">
        {address && address?.length > 0 ? (
          <div className="row justify-content-lg-center">
            {address && address?.length > 0 ? (
              <div
                className="col-lg-6  order-md-first order-last contactUsMap my-3 table-responsive sunEditor-react"
                dangerouslySetInnerHTML={{ __html: address[visibleMap]?.map }}
              ></div>
            ) : (
              ""
            )}
            <div className="col-lg-4 ">
              <div className="row">
                <div className="col-lg-12">
                  <p className="font-primary fw-500 fs-6 mb-0">Contact Us</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 section-left-border ps-4">
                  <p className="section-heading mb-3">
                    <span>Find us on map</span>
                  </p>
                  <div className="address-container">
                    {address.map((address, index) => {
                      return (
                        <div
                          className={`${
                            visibleMap == index ? "bg-light" : ""
                          }  cursor-pointer p-3 rounded`}
                          key={index + 1}
                          onClick={() => {
                            setVisibleMap(index);
                          }}
                        >
                          <p className="mb-1 fw-500 font-18">
                            {address?.location ?? ""}
                          </p>
                          <p className="dark-79 fw-500 mb-0 font-16 ">
                            {address?.full_address ?? ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>

      {/* <Footerweb /> */}
    </div>
  );
}
