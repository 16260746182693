import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import instance from "../../api/web_instance";
import { message } from "antd";
import { Helmet } from "react-helmet";
import ShimmerEffect from "../../component/ShimmerEffect";

export default function AboutUsPage() {
  const [countryData, setCountryData] = useState({});
  const [productData, setProductData] = useState({});
  const [whyChooseData, setWhyChooseData] = useState({});
  const [mapMarkers, setMapMarkers] = useState([]);
  const [history, setHistory] = useState({});
  const [aboutContent, setAboutContent] = useState("");

  const [seoData, setSeoData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const [geoUrl] = useState( "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json")

  // const geoUrl =
  //   "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

  const get_data = () => {
    setIsLoading(true);
    var config = {
      url: `/about-us`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setAboutContent(response?.data?.data?.about_content);
            setSeoData(response?.data?.seo_data);
            setCountryData(response?.data?.data?.country);
            setProductData(response?.data?.data?.about_product);
            setWhyChooseData(response?.data?.data?.why_choose_us);
            setHistory(response?.data?.data?.history);
            setIsLoading(false);

            var prev_countries = response?.data?.data?.country?.countries;

            var temp = [];
            var markers = [];

            if (prev_countries && prev_countries.length > 0) {
              prev_countries.forEach((element, index) => {
                temp.push(element.country);
                markers.push(
                  <Marker
                    key={index + 1}
                    coordinates={[element?.longitude, element.latitude]}
                  >
                    <text
                      textAnchor="middle"
                      fill=""
                      className="font-14 fw-500"
                      x={6}
                      y={0}
                    >
                      {element.sname}
                    </text>
                    <MdLocationPin className="text-danger fs-5" x={-5} y={0} />
                  </Marker>
                );
              });
            }
            setMapMarkers(markers);
          } else {
            message.error(response.data.message);
            setIsLoading(false);
          }
        } else {
          message.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_data();
    return () => {
      setCountryData({});
      setProductData({});
      setWhyChooseData({});
      setMapMarkers([]);
      setHistory({});
      setAboutContent({});
      setSeoData({});
      setIsLoading(false);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {seoData?.head_title ? seoData.head_title : "Oliver Rubber"}
        </title>
        <meta
          name="title"
          content={seoData?.meta_title ? seoData.meta_title : "Oliver Rubber"}
        ></meta>
        <meta
          name="description"
          content={
            seoData?.meta_description
              ? seoData.meta_description
              : "Oliver Rubber"
          }
        ></meta>
        <meta
          name="keyword"
          content={seoData?.meta_keyword ? seoData.meta_keyword : ""}
        ></meta>
        <link rel="canonical" href="https://oliverllp.com/about-us" />
      </Helmet>
      {/* <Navbarweb /> */}
      {/* <header className="container-fluid  p-0"></header> */}
      {/* ABOUT US SECTION */}
      <div className="container">
        <div className="row justify-content-center pt-4">
          <div className="col-lg-11">
            <p className="section-heading mb-3 text-center">About Us</p>
            {isLoading ? (
              <ShimmerEffect height={"180px"} width={"100%"} />
            ) : aboutContent ? (
              <div
                className="font-16 dark-79 text-center table-responsive sunEditor-react"
                dangerouslySetInnerHTML={{
                  __html: aboutContent ?? "",
                }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <section className="container-fluid ">
        <div className="row justify-content-lg-end">
          <div className="col-lg-10">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="font-primary fw-500 fs-6 mb-0">Countries</p>
                  </div>
                  <div className="col-lg-12 section-left-border ps-4">
                    {isLoading ? (
                      <ShimmerEffect height={"40px"} width={"70%"} />
                    ) : (
                      <h1 className="section-heading mb-3">
                        {countryData?.heading ?? ""}
                      </h1>
                    )}

                    {isLoading ? (
                      <ShimmerEffect height={"250px"} width={"100%"} />
                    ) : countryData?.description ? (
                      <div
                        className="font-16 dark-79 text-justify table-responsive sunEditor-react"
                        dangerouslySetInnerHTML={{
                          __html: countryData?.description ?? "",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-7 position-relative about-map">
                <div className="overlap-about-section-counts ">
                  <p>
                    {countryData?.count}+<span>Countries</span>
                  </p>
                </div>
                <ComposableMap>
                  <Geographies geography="https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json">
                    {({ geographies }) =>
                      geographies.map((geo) => (
                        <Geography
                          key={geo?.rsmKey}
                          geography={geo}
                          style={{
                            default: {
                              fill: "#AED6F1",
                            },
                            hover: {
                              fill: "#5D6D7E",
                            },
                            pressed: {
                              fill: "#E42",
                            },
                          }}
                        />
                      ))
                    }
                  </Geographies>
                  {mapMarkers ? mapMarkers : ""}
                </ComposableMap>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* PRODUCT SECTION */}
      <section className="container-fluid">
        <div className="row align-items-center px-0">
          <div className="col-lg-6 px-0 position-relative text-center">
            {isLoading ? (
              <div className="overlap-about-section-counts ">
                <p>
                  {productData?.count ? productData?.count + "+" : ""}
                  <span>Products</span>
                </p>
              </div>
            ) : (
              ""
            )}

            {isLoading ? (
              <ShimmerEffect height={"400px"} width={"50%"} />
            ) : productData?.image?._id ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${productData?.image?.path}`}
                className="img-fluid"
                alt={`${productData?.image?.alternate_text ??"ProductImg" } `}
                title={`${productData?.image?.title} `}
                caption={`${productData?.image?.caption} `}
                description={`${productData?.image?.description} `}
              />
            ) : (
              <img
                src="/images/aboutProduct.png"
                className="img-fluid"
                alt="ProductImg"
              />
            )}
          </div>
          <div className="col-lg-4">
            <div className="col-lg-12">
              <p className="font-primary fw-500 fs-6 mb-0">Products</p>
            </div>
            <div className="col-lg-12 section-left-border ps-4">
              {isLoading ? (
                <ShimmerEffect height={"40px"} width={"70%"} />
              ) : (
                <p className="section-heading mb-3">
                  <span>{productData?.heading ?? ""}</span>
                </p>
              )}

              {isLoading ? (
                <ShimmerEffect height={"180px"} width={"100%"} />
              ) : productData?.description ? (
                <div
                  className="font-16 dark-79 text-justify table-responsive sunEditor-react"
                  dangerouslySetInnerHTML={{
                    __html: productData?.description ?? "",
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      {/* WHY CHOOSE US SECTION */}
      <section className="container py-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <p className="font-primary fw-500 fs-6 mb-0">Why choose us?</p>
              </div>
              <div className="col-lg-12 section-left-border ps-4">
                {isLoading ? (
                  <ShimmerEffect height={"40px"} width={"80%"} />
                ) : (
                  <p className="section-heading mb-3">
                    <span>{whyChooseData?.heading ?? ""}</span>
                  </p>
                )}

                {isLoading ? (
                  <ShimmerEffect height={"250px"} width={"100%"} />
                ) : whyChooseData?.description ? (
                  <div
                    className="font-16 dark-79 text-justify table-responsive sunEditor-react"
                    dangerouslySetInnerHTML={{
                      __html: whyChooseData?.description ?? "",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row justify-content-center">
              <div className="col-lg-5 my-2 text-center">
                <div className="card border-0 background-light">
                  <div className="card-body why-us-card">
                    <div className="why-us-card-icon">
                      <span>{whyChooseData?.card1?.card_no}</span>
                    </div>
                    <div className="mt-3">
                      {
                        <p className="font-18 fw-bold">
                          {whyChooseData?.card1?.card_title}
                        </p>
                      }

                      <p className="dark-font fw-500 font-12">
                        {whyChooseData?.card1?.card_content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-5 my-2 text-center">
                <div className="card border-0 background-light">
                  <div className="card-body why-us-card">
                    <div className="why-us-card-icon">
                      <span>{whyChooseData?.card2?.card_no}</span>
                    </div>
                    <div className="mt-3">
                      <p className="font-18 fw-bold">
                        {whyChooseData?.card2?.card_title}
                      </p>
                      <p className="dark-font fw-500 font-12">
                        {whyChooseData?.card2?.card_content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-2 text-center">
                <div className="card border-0 background-light">
                  <div className="card-body why-us-card">
                    <div className="why-us-card-icon">
                      <span>{whyChooseData?.card3?.card_no}</span>
                    </div>
                    <div className="mt-3">
                      <p className="font-18 fw-bold">
                        {whyChooseData?.card3?.card_title}
                      </p>
                      <p className="dark-font fw-500 font-12">
                        {whyChooseData?.card3?.card_content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HISTORY US SECTION */}
      <section className="container pb-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-5">
            {isLoading ? (
              <ShimmerEffect height={"400px"} width={"100%"} />
            ) : history?.image?._id ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${history?.image?.path}`}
                alt={`${history?.image?.alternate_text ?? "history-img"}`}
                title={`${history?.image?.title}`}
                caption={`${history?.image?.caption}`}
                description={`${history?.image?.description}`}
                className="img-fluid rounded"
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6">
            <div className="row history-content-wrapper">
              <div className="col-lg-12">
                <p className="font-primary fw-500 fs-6 mb-0">History</p>
              </div>
              <div className="col-lg-12 section-left-border ps-4">
                {isLoading ? (
                  <ShimmerEffect height={"50px"} width={"70%"} />
                ) : (
                  <p className="section-heading mb-3">
                    <span>{history?.heading ?? ""}</span>
                  </p>
                )}

                {isLoading ? (
                  <ShimmerEffect height={"600px"} width={"100%"} />
                ) : history?.description ? (
                  <div
                    className="font-16 dark-79 text-justify table-responsive sunEditor-react"
                    dangerouslySetInnerHTML={{
                      __html: history?.description ?? "",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
