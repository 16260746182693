import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {message} from 'antd';
import instance from "../../api/web_instance";

function DynamicPage() {
  const {slug} = useParams()

  const [pageData , setPageData] = useState({})

  const get_data = () => {
    var config = {
      url: `/dynamic-page-content/${slug}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setPageData(response?.data?.data)
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_data();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <p className="text-center text-capitalize fw-500 fs-30">
              {pageData?.page_title}
          </p>
          <div dangerouslySetInnerHTML={{ __html: pageData?.page_description ?? ""}} className="text-justify fs-18">
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicPage;
