import axios from "axios"; 

const instance = axios.create({
  baseURL : process.env.REACT_APP_API_BASE_URL+"/adminapi",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    // 'Content-Type': 'multipart/form-data',
    Authorization: "Bearer " + localStorage.getItem("authToken"),
  },
});

export default instance;