import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Button, Form, Input, Modal, Select, message } from "antd";
import instance from "../../../api/api_instance";
function SocialMedia() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm()

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // EDIT MODAL
  const showEditModal = (index,record) => {
    editForm.setFieldsValue({id:index,social_name:record?.social_name,social_link:record?.social_link,social_icon:record?.social_icon})
    setIsEditModalOpen(true);
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const onSocialMediaSelect = (value, event) => {
    form.setFieldsValue({ social_name: event.label });
  };

  const onSocialMediaEditSelect = (value,event)=>{
    editForm.setFieldsValue({ social_name: event.label });
  }
  const get_socialmedia = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/fetch-socialmedia`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            setSocialMediaData(response?.data?.data);
            message.success({ key: "loading", content: "Data Loaded." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  const deleteSocialMedia = (id) => {

    if(window.confirm('Are you sure to delete')){
      var config = {
        url: `/delete-socialmedia/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (
            
            response.status >= 200 &&
            response.status < 300
          ) {
            if (response.data.status === true) {
              setIsModalOpen(false);
              get_socialmedia();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error.message);
          }
        });
    }
  };

  const onFinish = (values) => {
    var config = {
      url: `/insert-socialmedia`,
      method: "POST",
      data: {
        social_name: values?.social_name?.trim(),
        social_link: values?.social_link?.trim(),
        social_icon: values?.social_icon?.trim(),
      },
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            message.success(response.data.message);
            setIsModalOpen(false);
            get_socialmedia();
            form.resetFields();
            // setEmailData(response?.data?.data);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  const onEdit = (values) => {
   
    var config = {
      url: `/edit-socialmedia/${values?.id}`,
      method: "POST",
      data: {
        social_name: values?.social_name?.trim(),
        social_link: values?.social_link?.trim(),
        social_icon: values?.social_icon?.trim(),
      },
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            message.success(response.data.message);
            
            setIsEditModalOpen(false);
            get_socialmedia();
            // setEmailData(response?.data?.data);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };
  useEffect(() => {
    get_socialmedia();
  }, []);

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="card border-0">
        <div className="card-header border-bottom d-flex justify-content-between py-3">
          <p className="mb-0 fw-500 fs-6">Social Links</p>
          <button
            className="mb-0 primary-font border-0 bg-transparent"
            onClick={showModal}
          >
            <RiAddCircleLine className="fs-5 primary-font" />
          </button>
        </div>

        <div className="card-body">
          <table className="table mb-0">
           <tbody>{socialMediaData && socialMediaData?.length > 0
              ? socialMediaData.map((elment, index) => {
                  return (
                    <tr key={index + 1}>
                      <td>
                        <p className="mb-0" dangerouslySetInnerHTML={{__html:elment.social_icon}}>
                        </p>
                      </td>
                      <td>
                        <a
                          href={elment?.social_link} target="_blank"
                          className="text-center fw-500  d-block text-dark"
                        >
                          {elment?.social_name}
                        </a>
                      </td>
                      <td className="text-end">
                        <button className="btn btn-sm d-inline-flex fs-5 secondary-font text-end" onClick={()=>showEditModal(index,elment)}>
                          <AiOutlineEdit />
                        </button>
                        <button className="btn btn-sm d-inline-flex fs-5 primary-font text-end" onClick={()=>deleteSocialMedia(index)}>
                          <AiOutlineDelete />
                        </button>
                      </td>
                    </tr>
                  );
                })
              :null}</tbody>
          </table>
        </div>
      </div>
      {/* ADD SOCIAL MEDIA */}
      <Modal
        title="Add Social media"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onFinish}
        >
     
          <Form.Item
            name="social_icon"
            rules={[
              { required: true, message: "Please select your social icon!" },
            ]}
          >
            <Select
              //   defaultValue="lucy"
              onChange={(value, event) => onSocialMediaSelect(value, event)}
              placeholder="Select social media"
              options={[
                {
                  value: '<i class="fa-brands fa-whatsapp"></i>',
                  label: "Whatsapp",
                },
                {
                  value: '<i class="fa-brands fa-facebook"></i>',
                  label: "Facebook",
                },
                {
                  value: '<i class="fa-brands fa-instagram"></i>',
                  label: "Instagram",
                },
                {
                  value: '<i class="fa-brands fa-x-twitter"></i>',
                  label: "Twitter",
                },
                {
                  value: '<i class="fa-brands fa-linkedin"></i>',
                  label: "Linkedin",
                },
                {
                  value: '<i class="fa-brands fa-skype"></i>',
                  label: "Skype",
                },
                {
                  value: '<i class="fa-brands fa-youtube"></i>',
                  label: "Youtube",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="social_name"
            rules={[{ required: true, message: "Please input your name!" }]}
            className="d-none"
          >
            <Input
              placeholder="Social media name"
              className="d-none"
              disabled
            />
          </Form.Item>
          <Form.Item
            name="social_link"
            rules={[
              {
                required: true,
                type: "url",
                message: "Please input your social media link!",
              },
            ]}
          >
            <Input placeholder="Enter Social media link" />
          </Form.Item>

          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>

{/* EDIT SOCIAL MEDIA MODAL */}
      <Modal
        title="Edit Social media"
        visible={isEditModalOpen}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          form={editForm}
          onFinish={onEdit}
        >
          <Form.Item
            name="social_icon"
            rules={[
              { required: true, message: "Please select your social icon!" },
            ]}
          >
            <Select
              //   defaultValue="lucy"
              onChange={(value, event) => onSocialMediaEditSelect(value, event)}
              placeholder="Select social media"
              options={[
                {
                  value: '<i class="fa-brands fa-whatsapp"></i>',
                  label: "Whatsapp",
                },
                {
                  value: '<i class="fa-brands fa-facebook"></i>',
                  label: "Facebook",
                },
                {
                  value: '<i class="fa-brands fa-instagram"></i>',
                  label: "Instagram",
                },
                {
                  value: '<i class="fa-brands fa-x-twitter"></i>',
                  label: "Twitter",
                },
                {
                  value: '<i class="fa-brands fa-linkedin"></i>',
                  label: "Linkedin",
                },
                {
                  value: '<i class="fa-brands fa-skype"></i>',
                  label: "Skype",
                },
                
              ]}
            />
          </Form.Item>
          <Form.Item
            name="id"
            rules={[{ required: true, message: "Please input your id!" }]}
            className="d-none"
          >
            <Input
              placeholder="id"
              className="d-none"
              disabled
            />
          </Form.Item>
          <Form.Item
            name="social_name"
            rules={[{ required: true, message: "Please input your name!" }]}
            className="d-none"
          >
            <Input
              placeholder="Social media name"
              className="d-none"
              disabled
            />
          </Form.Item>
          <Form.Item
            name="social_link"
            rules={[
              {
                required: true,
                type: "url",
                message: "Please input your social media link!",
              },
            ]}
          >
            <Input placeholder="Enter Social media link" />
          </Form.Item>

          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default SocialMedia;
