import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { BiRightArrowCircle } from "react-icons/bi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Input, message, Pagination } from "antd";
import instance from "../../api/web_instance";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import ShimmerEffect from "../../component/ShimmerEffect";
const { Search } = Input;

export default function ProductList() {
  const navigate = useNavigate();
  const [productCategories, setProductCategories] = useState([]);
  const [productGridData, setProductGridData] = useState([]);
  const [seoData, setSeoData] = useState({});
  const [randomProduct, setRandomProduct] = useState({});
  const [searchQry, setSearchQry] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const { state } = useLocation();
  const { slug } = useParams();//CATEGORY SLUG

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const page = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [current, setCurrent] = useState(page);

  const get_categories = () => {
    setIsLoading(true);
    var config = {
      url: `/product-categories`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setProductCategories(response?.data?.data);
            setIsLoading(false);
          } else {
            message.error(response.data.message);
            setIsLoading(false);
          }
        } else {
          message.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setIsLoading(false);
      });
  };
  const get_products = () => {
    setIsProductLoading(true);
    var url = `/products/${slug}?search=${searchQry}&page=${current}`;
    if (slug == "all") {
      url = `/products?search=${searchQry}&page=${current}`;
    } else {
      url = `/products/${slug}?search=${searchQry}&page=${current}`;
    }
    var config = {
      url: url,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setProductGridData(response?.data?.data);
            setTotalRecords(response?.data?.totalCount);
            setSeoData(response?.data?.seoData);
            if (response.data.data?.length > 0) {
              var productData = response?.data?.data;
              var randomData =
                productData[Math.floor(Math.random() * productData.length)];
              setRandomProduct(randomData);
            }
            setIsProductLoading(false);
          } else {
            message.error(response.data.message);
            setIsProductLoading(false);
          }
        } else {
          message.error(response.message);
          setIsProductLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setIsProductLoading(false);
      });
  };

  useEffect(() => {
    setCurrent(1);
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
    get_categories();
  }, []);

  useEffect(() => {
    get_products();
  }, [state, searchQry, current, slug]);

  // ON PAGINATION PAGE CHANGE
  const onChange = (page) => {
    if (page > 0 && page <= totalRecords) {
      current !== page && navigate(`/products/${slug}?page=${page}`);
      setCurrent(page);
    }
    // setCurrent(page);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  // const randomIndex = Math.floor(Math.random() * arr.length);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData ? seoData?.head_title:"Oliver Rubber Products"}</title>
        <meta name="title" content={seoData ? seoData?.meta_title:"Oliver Rubber"}></meta>
        <meta name="description" content={seoData ? seoData?.meta_desc: "Oliver Rubber"}></meta>
        <meta name="keyword" content={seoData ? seoData?.meta_keyword: ""}></meta>

        <link rel="canonical" href={`https://oliverllp.com/products/${slug}`} />
      </Helmet>
      {/* <Navbarweb /> */}
      <header className="container-fluid  p-0">
        <div className=" background-light ">
          <div className="container ">
            <div className="row justify-content-lg-center">
              <div className="col-lg-10 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    {isLoading ? (
                      <ShimmerEffect height={"20px"} width={"300px"} />
                    ) : (
                      <h1 className="font-primary fw-500 fs-6 mb-0">
                        {randomProduct?.category?.name ?? ""}
                      </h1>
                    )}
                  </div>
                </div>
                {isLoading ? (
                  <div className="row py-2">
                    <div className="col-lg-7">
                      <ShimmerEffect height={"50px"} width={"100%"} />
                      <ShimmerEffect height={"20px"} width={"100%"} />
                      <ShimmerEffect height={"20px"} width={"100%"} />
                    </div>
                    <div className="col-lg-5 text-end">
                      <ShimmerEffect height={"200px"} width={"50%"} />
                    </div>
                  </div>
                ) : randomProduct?.name ? (
                  <div className="row">
                    <div className="col-lg-7 section-left-border ps-4">
                      <p className="section-heading mb-2">
                        <span>{randomProduct?.name ?? ""}</span>
                      </p>
                      <p className="font-18 fw-500 dark-79 w-75 text-justify">
                        {randomProduct?.introduction}
                      </p>
                    </div>
                    <div className="col-lg-5 text-md-end  product-header-img overflow-hidden">
                      {randomProduct?.images &&
                      randomProduct?.images?.length > 0 ? (
                        <img
                          src={`${
                            process.env.REACT_APP_API_URL +
                            "/" +
                            randomProduct?.images[0]?.path
                          }`}
                          alt={`${randomProduct?.images[0]?.alternate_text ?? "product-img"}`}
                          title={`${randomProduct?.images[0]?.title}`}
                          caption={`${randomProduct?.images[0]?.caption}`}
                          description={`${randomProduct?.images[0]?.description}`}
                          className="img-fluid"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="container">
        <div className="row justify-content-lg-center">
          <div className="col-lg-10 py-5">
            <div className="row">
              <div className="col-md-5 my-2 col-lg-3">
                <div className="card border-0 custom-card-shadow">
                  <div className="card-header background-primary">
                    <p className="mb-0 text-white fw-500">Category</p>
                  </div>
                  {isLoading ? (
                    <div className="card-body pb-0">
                      <ul className="list-unstyled mb-0">
                       { [...Array(10)].map((element,index)=>{
                        return <li key={index + 1} className="mt-3"><ShimmerEffect height={"20px"} width ={"100%"}/> </li>
                       })}

                      </ul>
                    </div>
                  ) : (
                    <div className="card-body pb-0">
                      <ul className="list-unstyled mb-0">
                        <Link
                          to={`/products/all`}
                          state={{
                            id: "all",
                            name: "All",
                          }}
                          className="d-block dark-79 fw-500 category-hover"
                        >
                          <li className=" d-flex justify-content-between">
                            <p
                              className={`${
                                slug == "all" ? "font-primary" : ""
                              }`}
                            >
                              All
                            </p>
                            <span>
                              <BsChevronRight />
                            </span>
                          </li>
                        </Link>
                        {productCategories?.length > 0
                          ? productCategories?.map((element, index) => {
                              // var convertToLower = element.name.toLowerCase();
                              // var slug = convertToLower
                              //   .split(/[' '/.()]+/)
                              //   .join("-");
                              // var some = encodeURI(slug);
                              return (
                                <Link
                                  key={index + 1}
                                  to={`/products/${element?.category_slug}`}
                                  // state={{
                                  //   id: element?._id,
                                  //   name: element?.name,
                                  // }}
                                  className="d-block dark-79 fw-500 font-16 category-hover"
                                >
                                  <li className="d-flex justify-content-between">
                                    <p
                                      className={`${
                                        slug === element?.category_slug
                                          ? "font-primary"
                                          : ""
                                      }`}
                                    >
                                      {element?.name}
                                    </p>
                                    <span>
                                      <BsChevronRight />
                                    </span>
                                  </li>
                                </Link>
                              );
                            })
                          : ""}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-7 my-2 col-lg-9">
                <div className="row">
                  <div className="col-lg-6">
                    <p className="fw-500 fs-6 text-capitalize">
                        Products
                      / {slug?.replaceAll("-", " ")}
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <Search
                      placeholder="Search for product..."
                      onChange={(e) => {
                        setSearchQry(e.target.value);
                      }}
                      // onSearch={onSearch}
                      className="rounded"
                    />
                  </div>
                </div>
                {/* PRODUCT GRID */}
                {/* {
                  isLoading ? 
                } */}
                <div className="row ">
                  {
                    isProductLoading ?  [...Array(6)].map((element,index)=>{
                      return <div key={index + 1} className="col-sm-6 col-md-6 col-lg-4  my-2">
                      <div className="card custom-card-shadow h-100 border-0">
                        <div className="card-body text-center">
                          <ShimmerEffect height ={"25px"} width={"90%"} />
                          <ShimmerEffect height ={"25px"} width={"50%"} />
                          <ShimmerEffect height ={"15px"} width={"60%"} />
                          <ShimmerEffect height ={"200px"} width={"80%"} />
                        </div>
                      </div>
                      </div>
                    })  :
                 
                  productGridData && productGridData?.length > 0
                    ? productGridData?.map((element, index) => {
                        return (
                          <div
                            key={index + 1}
                            className="col-sm-6 col-md-6 col-lg-4  my-2"
                          >
                            <div className="card custom-card-shadow h-100 border-0">
                              <div className="card-body px-2 py-3 text-center">
                                <p className="mb-2 font-18 fw-500 dark-font">
                                  {element?.name ?? ""}
                                </p>

                                <Link
                                  to={`/product-details/${element?.product_slug}`}
                                  state={{ id: element?._id }}
                                  className="fw-500 font-16"
                                >
                                  Know More
                                  <span>
                                    <BiRightArrowCircle className="fs-5 ms-2" />
                                  </span>
                                </Link>
                                <div className="mt-3">
                                  {element?.images &&
                                  element?.images?.length > 0 ? (
                                    <img
                                      src={`${
                                        process.env.REACT_APP_API_URL +
                                        "/" +
                                        element?.images[0]?.path
                                      }`}
                                      alt={element?.images[0]?.alternate_text ?? "product-img"}
                                      title={element?.images[0]?.title}
                                      caption={element?.images[0]?.caption}
                                      description={element?.images[0]?.description}
                                      className="img-fluid"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : <div className="col-md-12 text-center "> <img src ="/images/noData.jpg" alt="no_dataImg" className="img-fluid"/></div>}
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12 text-center">
                    {isLoading ? "" : totalRecords && totalRecords > 9 ? (
                      <Pagination
                        current={current}
                        pageSize={9}
                        onChange={onChange}
                        total={totalRecords}
                        itemRender={itemRender}
                      />
                    ) : (
                      ""
                    ) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footerweb /> */}
    </div>
  );
}
