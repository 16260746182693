import React, { useEffect, useState } from "react";
import { Input, Modal, Form, message } from "antd";
import instance from "../api/api_instance";
import ButtonLoader from "./ButtonLoader";
function ImageSeoModal({ imageId }) {

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageData , setImageData] = useState({})
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFormSubmit = (values) => {
    var config = {
      url: `/image/update-seo`,
      method: "POST",
      data: {
        title: values?.title,
        caption: values?.caption,
        description: values?.description,
        alternate_text: values?.alternate_text,
        id: imageId,
      },
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            handleCancel()
            message.success({ key:"loading" ,content: response.data.message });
          } else {
            message.error({ key:"loading",content: response.data.message });
            setIsLoading(false);
          }
        } else {
          message.error({ key:"loading",content: "Something went wrong! try again later" });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({
            key: "loading",
            content: error?.response?.data?.message,
          });
        } else {
          message.error({ key: "loading", content: error?.message });
        }
        setIsLoading(false);
      });
  };

  const fetchImageData = ()=>{
    var config = {
      url: `/image/get-seo`,
      method: "POST",
      data: {
        id: imageId,
      },
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            setImageData(response?.data?.data)
          } else {
            message.error({ content: response.data.message });
            setIsLoading(false);
          }
        } else {
          message.error({ content: "Something went wrong! try again later" });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({
            key: "submit",
            content: error?.response?.data?.message,
          });
        } else {
          message.error({ key: "submit", content: error?.message });
        }
        setIsLoading(false);
      });
  }

  useEffect(()=>{
    if(imageId){
      fetchImageData()
    }
   
  },[imageId])
  useEffect(()=>{
    form.setFieldsValue({
      title : imageData?.title,
      caption : imageData?.caption,
      description :imageData?.description,
      alternate_text : imageData?.alternate_text
    })
  },[imageData,form])
  return (
    <div>
      <div className="my-2">
        <button
          className="btn btn-sm btn-outline-secondary fw-500"
          type="button"
          onClick={(e) => showModal(e)}
        >
          Set seo
        </button>
      </div>
      <Modal
        title="Image Seo"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form
          form={form}
          name="imageSeoForm"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          requiredMark={false}
          layout="vertical"
          onFinish={onFormSubmit}
        >
          <Form.Item
            name="title"
            label="title"
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
            ]}
          >
            <Input placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name="caption"
            label="Caption"
            rules={[
              {
                required: true,
                message: "Please enter caption",
              },
            ]}
          >
            <Input placeholder="Enter Caption" />
          </Form.Item>
          <Form.Item
            name="alternate_text"
            label="Alternate text"
            rules={[
              {
                required: true,
                message: "Please enter alternate text!",
              },
            ]}
          >
            <Input placeholder="Enter alternate text" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter description",
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Enter description" />
          </Form.Item>
          <Form.Item>
            <button type="submit" disabled={isLoading} className="form-btn">
              {isLoading ? <ButtonLoader /> : "Submit"}
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ImageSeoModal;
