import React, { useEffect, useState } from "react";
import { RiContactsLine } from "react-icons/ri";
import { AiOutlineHome, AiOutlineEye, AiOutlineGoogle } from "react-icons/ai";
import { BiAddToQueue, BiNews } from "react-icons/bi";
import { BsHeadphones, BsNewspaper } from "react-icons/bs";
import { HiViewGridAdd } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { ImInfo } from "react-icons/im";
import { RiPagesLine } from "react-icons/ri";
import { Layout, Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;

const { SubMenu } = Menu;

function Sidebar() {
  const dispatch = useDispatch();

  const location = useLocation();

  const sidebar = useSelector((state) => state.sidebar.sidebar_status);
  const adminData = useSelector((state) => state?.auth?.admin);

  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  const setSidebarStatus = () => {
    if (sidebar === "close") {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "open" });
    } else {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" });
    }
  };
  const closeSidebar = () => {
    if (window.innerWidth <= 1200) {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" });
    }
  };
  useEffect(() => {
    return setCurrentLocation(location.pathname);
  }, [location]);

  return (
    <div className={`sider-wrapper`}>
      <Sider>
        <div className={`sidebar-top`}>
          <div className="side-logo ">
            <img src="/images/logo.png" alt="" />
            <div className="sidebar-close-button" onClick={setSidebarStatus}>
              <MdClose />
            </div>
          </div>

          <div className="container py-3">
            <div className="row">
              <div className="col-4 col-md-4 side-profile-img">
                <img
                  src={
                    adminData?.profilePic
                      ? process.env.REACT_APP_API_URL +
                        "/" +
                        adminData.profilePic
                      : "/images/Default-registration-profile.png"
                  }
                  alt=""
                />
              </div>
              <div className="col-8 col-md-8 align-items-center">
                <p className="mb-0 side-profile-greetings">Welcome</p>
                <p className="mb-0 text-capitalize side-profile-details">
                  {adminData.name ? adminData.name : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={currentLocation}
          // defaultOpenKeys={['sub1']}
          style={{ height: "100%" }}
          className={`sidemenu-style`}
          selectedKeys={currentLocation}
        >
          <Menu.Item key="/admin">
            <AiOutlineHome className="text-white" />
            <Link to="/admin" onClick={closeSidebar}>
              Home
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/contact-us">
            <RiContactsLine className="text-white" />
            <Link to="/admin/contact-us" onClick={closeSidebar}>
              Contact Us
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/about-us">
            <ImInfo className="text-white" />
            <Link to="/admin/about-us" onClick={closeSidebar}>
              About Us
            </Link>
          </Menu.Item>
          <SubMenu key="products" icon={<HiViewGridAdd />} title="Products">
            <Menu.Item key="/admin/product-category">
              <BiAddToQueue className="text-white" />
              <Link to="/admin/product-category" onClick={closeSidebar}>
                Product Category
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/add-product">
              <HiViewGridAdd className="text-white" />
              <Link to="/admin/add-product" onClick={closeSidebar}>
                Add Product
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/products">
              <AiOutlineEye className="text-white" />
              <Link to="/admin/products" onClick={closeSidebar}>
                View Products
              </Link>
            </Menu.Item>
          </SubMenu>

         
          <SubMenu key="blogs" icon={<BsNewspaper />} title="Blog">
            <Menu.Item key="/admin/blog">
              <BsNewspaper className="text-white" />
              <Link to="/admin/blog" onClick={closeSidebar}>
                Blog
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/blog/add">
              <BiNews className="text-white" />
              <Link to="/admin/blog/add" onClick={closeSidebar}>
                Add Blog
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="pages" icon={<RiPagesLine />} title="Dynamic Pages">
            <Menu.Item key="/admin/page/add">
              <RiPagesLine className="text-white" />
              <Link to="/admin/page/add" onClick={closeSidebar}>
                Add Page
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/pages">
              <RiPagesLine className="text-white" />
              <Link to="/admin/pages" onClick={closeSidebar}>
                All Pages
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/admin/seo">
            <AiOutlineGoogle className="text-white" />
            <Link to="/admin/seo" onClick={closeSidebar}>
              Manage Seo
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/enquiry">
            <BsHeadphones className="text-white" />
            <Link to="/admin/enquiry" onClick={closeSidebar}>
              Enquiry
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
}

export default Sidebar;
