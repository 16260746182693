import React, { useEffect, useState } from "react";
import instance from "../../api/web_instance";
import { message } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function MoreBlog() {
  const [blogData, setBlogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const get_data = () => {
    setIsLoading(true);
    var config = {
      url: `/blog?limit=10`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setBlogData(response.data.data);
          } else {
            message.error({ key: "loading", content: response.data.message });
            setIsLoading(false);
          }
        } else {
          message.error({ key: "loading", content: response.message });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    get_data();
  }, []);
  return (
    <div>
      {blogData && blogData.length > 0
        ? blogData.map((element,key) => {
            return (
              <div key={key+1} className="row cursor-pointer" onClick={()=>navigate(`/blog/${element.slug}`)}>
                <div className="col-4">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${element?.image?.path}`}
                    alt={`${element?.image?.alternate_text ?? "blog-image"}`}
                    title={element?.image?.title}
                    caption={element?.image?.caption}
                    description={element?.image?.description}
                    className="img-fluid news-image "
                  />
                </div>
                <div className="col-8">
                  <p className="fw-500 text-justify font-18 mb-2">
                    {element?.headline}
                  </p>
                  <p className="dark-79 font-16 mb-0 fw-500">
                    {moment(element?.createdAt).fromNow()} | oliver rubber
                  </p>
                </div>
                <div className="col-12">
                  <div className="hr-100"></div>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default MoreBlog;
